import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "../i18n"; // Assuming i18n setup for language switching
import { url } from "../Components/Common/Url";
import { useNavigate } from "react-router-dom";
import { removeSpaceFromString, uploadFileAndGetUrl } from "../utils/common";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { editorConfiguration } from "../common/ClassicEditor.js";
import JoditEditor from "jodit-react";

const CreatePageForm = () => {
  const editor = useRef(null);
  const editorNewSectionWithImage = useRef(null);
  const editornewSectionWithoutImages = useRef(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.user);
  const [sliderImages, setSliderImages] = useState([]);
  const [grid, setGrid] = useState([]);
  const [newSectionWithImages, setNewSectionWithImages] = useState([]);
  const [isForm, setIsForm] = useState(false);
  const [isBackBtn, setIsBackBtn] = useState(false);
  const [newSectionWithoutImages, setNewSectionWithoutImages] = useState([]);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [parentSlugList, setParentSlugList] = useState([]);
  const [formData, setFormData] = useState({
    parentSlug: "",
    slug: "",
    titleEn: "",
    titleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    metaTitleEn: "",
    metaDescriptionEn: "",
    metaKeywordsEn: "",
    metaTitleAr: "",
    metaDescriptionAr: "",
    metaKeywordsAr: "",
    formUrl: "",
    backBtnText_ar: "",
    backBtnText: "",
    redirectUrl: "",
  });

  const [gridBgImage, setGridBgImage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fileUpload = async (ev, index) => {
    const file = ev.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        sliderImages[index].image = result.imagePath;
      } else {
        sliderImages[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
    console.log(sliderImages);
  };

  const deleteFile = (index) => {
    const filteredImages = sliderImages?.filter(
      (_image, _index) => _index !== index
    );
    setSliderImages(filteredImages);
  };

  const addNewImage = () => {
    setSliderImages([...sliderImages, { image: null, image_ar: null }]);
  };

  const uploadSectionImage = async (ev, index) => {
    const file = ev.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        newSectionWithImages[index].image = result.imagePath;
      } else {
        newSectionWithImages[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const deleteNewSectionWithImages = (index) => {
    const filteredSections = newSectionWithImages?.filter(
      (_image, _index) => _index !== index
    );
    setNewSectionWithImages(filteredSections);
  };

  const addNewSectionWithImages = () => {
    setNewSectionWithImages([
      ...newSectionWithImages,
      {
        content: "",
        content_ar: "",
        image: null,
        image_ar: null,
        backgroundColor: "",
        backgroundImage: "",
      },
    ]);
  };

  const handleBackgroundImageChange = async (index, ev, isWithImageSection) => {
    let updatedSections;
    let file = ev.target.files[0];
    try {
      const imageUrl = await uploadFileAndGetUrl(file, token);

      if (isWithImageSection) {
        updatedSections = [...newSectionWithImages];
        updatedSections[index].backgroundImage = imageUrl;
        setNewSectionWithImages(updatedSections);
      } else {
        updatedSections = [...newSectionWithoutImages];
        updatedSections[index].backgroundImage = imageUrl;
        setNewSectionWithoutImages(updatedSections);
      }
    } catch (error) {
      console.error("Error uploading background image:", error);
    }
  };

  const deleteNewSectionWithoutImages = (index) => {
    const filteredSections = newSectionWithoutImages?.filter(
      (_image, _index) => _index !== index
    );
    setNewSectionWithoutImages(filteredSections);
  };

  const addNewSectionWithoutImages = () => {
    setNewSectionWithoutImages([
      ...newSectionWithoutImages,
      { content: "", content_ar: "", backgroundImage: "" },
    ]);
  };

  const deleteGridElemment = (index) => {
    const filteredSections = grid?.filter((_image, _index) => _index !== index);
    setGrid(filteredSections);
  };

  const addGridElement = () => {
    setGrid([
      ...grid,
      { content: "", content: "", image: null, image_ar: null },
    ]);
  };

  const handleEditorChange = (data, language) => {
    if (editor.current) {
      if (language === "en") {
        setFormData((prevState) => ({
          ...prevState,
          descriptionEn: data || "", // prevent null or undefined value
        }));
      } else if (language === "ar") {
        setFormData((prevState) => ({
          ...prevState,
          descriptionAr: data || "", // prevent null or undefined value
        }));
      }
    }
  };

  const uploadGridImage = async (ev, index) => {
    const file = ev.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        grid[index].image = result.imagePath;
      } else {
        grid[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newFormData = {
      ...formData,
      slug: removeSpaceFromString(formData?.slug),
      grid,
      gridBgImage,
      sliderImages,
      newSectionWithImages,
      newSectionWithoutImages,
      landingPage: isLandingPage,
      language: i18n.language,
      form: isForm,
      selectedOrder,
      isBackBtn,
    };
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/pages`,
        newFormData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      toast.success("Page created successfully!");
      setFormData({
        parentSlug: "",
        slug: "",
        titleEn: "",
        titleAr: "",
        descriptionEn: "",
        descriptionAr: "",
        metaTitleEn: "",
        metaDescriptionEn: "",
        metaKeywordsEn: "",
        metaTitleAr: "",
        metaDescriptionAr: "",
        metaKeywordsAr: "",
        formUrl: "",
        backBtnText: "",
        backBtnText_ar: "",
        redirectUrl: "",
      });
      setSliderImages([]);
      setGrid([]);
      setNewSectionWithImages([]);
      setNewSectionWithoutImages([]);
      setIsLandingPage(false);
      setIsForm(false);
      setSelectedOrder({
        slider: 0,
        newSectionWithImages: 0,
        newSectionWithOutImages: 0,
        grid: 0,
        form: 0,
      });
      setGridBgImage();
      navigate("/admin/page-list");
    } catch (error) {
      console.error("Error creating page:", error);
      toast.error("There was an error creating the page.");
    }
  };

  const handleGridLabelChange = (ev, _index) => {
    const newGrid = [...grid];
    if (i18n.language === "en") {
      newGrid[_index].content = ev.target.value;
    } else {
      newGrid[_index].content_ar = ev.target.value;
    }
    setGrid(newGrid);
  };

  const handleGridDescriptionChange = (ev, _index) => {
    const newGrid = [...grid];
    if (i18n.language === "en") {
      newGrid[_index].description = ev.target.value;
    } else {
      newGrid[_index].description_ar = ev.target.value;
    }
    setGrid(newGrid);
  };

  const fetchPages = async () => {
    try {
      const response = await axios.get(`${url}/api/v1/setting/pages`, {
        headers: {
          token: `${token}`,
        },
      });
      const slugs = response?.data?.map((s) => s.slug);
      setParentSlugList(slugs || []);
    } catch (err) {
      console.error("Error fetching pages:", err);
    }
  };

  const [selectedOrder, setSelectedOrder] = useState({
    slider: 0,
    newSectionWithImages: 0,
    newSectionWithOutImages: 0,
    grid: 0,
    form: 0,
  });

  const handleOrderChange = (e, name) => {
    const { value } = e.target;
    setSelectedOrder((prev) => {
      const updatedOrder = { ...prev };
      updatedOrder[name] = parseInt(value, 10);
      return updatedOrder;
    });
  };

  const getDisabledOptions = (name) => {
    const selectedValues = Object.values(selectedOrder).filter(
      (value) => value !== 0
    );
    return selectedValues;
  };

  const resetPosition = () => {
    setSelectedOrder({
      slider: 0,
      newSectionWithImages: 0,
      newSectionWithOutImages: 0,
      grid: 0,
      form: 0,
    });
  };

  const handleGridBackgroundImageChange = async (event) => {
    const file = event.target.files[0];

    try {
      const imageUrl = await uploadFileAndGetUrl(file, token, url);
      setGridBgImage(imageUrl);
    } catch (error) {
      console.error("Error uploading section image:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row card pt-3">
        <h3 classname="pt-3">Add New Page</h3>
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <div className="text-end  w-100">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={() => resetPosition()}
                  >
                    Reset Position
                  </button>
                </div>

                {activeLanguage === "en" ? (
                  <>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "slider")}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3 w-100">
                      <label htmlFor="slug" className="form-label ">
                        Slider
                      </label>
                      {sliderImages &&
                        sliderImages.map((_file, _index) => (
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label mt-3">
                              Image
                            </label>
                            <div
                              style={{
                                display: "flex",
                                gap: "1%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="file"
                                className="form-control"
                                id="slug"
                                name="slug"
                                // value={_file.image}
                                onChange={(ev) => fileUpload(ev, _index)}
                                required
                              />
                              <div
                                style={{
                                  padding: "1%",
                                  height: "2vw",
                                  width: "2vw",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                onClick={() => deleteFile(_index)}
                              >
                                &times;
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "10vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addNewImage}
                      >
                        <div style={{ fontWeight: "bold" }}>Add New Image</div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) =>
                        handleOrderChange(e, "newSectionWithImages")
                      }
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="slug" className="form-label mt-3">
                        Add New Section With Image
                      </label>
                      {newSectionWithImages &&
                        newSectionWithImages.map((_section, _index) => (
                          <div className="mb-3" key={_index}>
                            <div className="w-100">
                              <JoditEditor
                                className="w-100"
                                ref={editorNewSectionWithImage}
                                value={_section.content || ""}
                                tabIndex={2}
                                onChange={(newContent) => {
                                  const updatedSections = [
                                    ...newSectionWithImages,
                                  ];
                                  updatedSections[_index].content = newContent;
                                  setNewSectionWithImages(updatedSections);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "1%",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                backgroundImage: _section.backgroundImage
                                  ? `url(${_section.backgroundImage})`
                                  : "none",
                                backgroundSize: "cover", // Makes the image cover the div
                                backgroundPosition: "center",
                                padding: "20px",
                                borderRadius: "8px",
                                height: "auto",
                                maxHeight: "500px", // Adjust this according to your layout
                              }}
                            >
                              {/* <CKEditor
                                editor={ClassicEditor}
                                data={_section.content}
                                onChange={(event, editor) => {
                                  const content = editor.getData();
                                  const updatedSections = [
                                    ...newSectionWithImages,
                                  ];
                                  updatedSections[_index].content = content;
                                  setNewSectionWithImages(updatedSections);
                                }}
                              /> */}

                              <label className="form-label w-100">
                                Content Image (Display Image)
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(ev) =>
                                    uploadSectionImage(ev, _index)
                                  }
                                  required
                                />
                              </label>
                              {_section.image && (
                                <div style={{ marginTop: "10px" }}>
                                  <img
                                    src={url + "/" + _section.image}
                                    alt="Content"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "200px",
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              )}
                              <label className="form-label w-100">
                                Background Image
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(ev) =>
                                    handleBackgroundImageChange(
                                      _index,
                                      ev,
                                      true
                                    )
                                  }
                                  required
                                />
                              </label>
                              <div
                                style={{
                                  padding: "1%",
                                  height: "2vw",
                                  width: "2vw",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                onClick={() =>
                                  deleteNewSectionWithImages(_index)
                                }
                              >
                                &times;
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "11vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={addNewSectionWithImages}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          Add New Section
                        </div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) =>
                        handleOrderChange(e, "newSectionWithOutImages")
                      }
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <div className="mt-3">
                        <label htmlFor="slug" className="form-label">
                          Add New Section Without Image
                        </label>
                        {newSectionWithoutImages &&
                          newSectionWithoutImages.map((_section, _index) => (
                            <div className="mb-3" key={_index}>
                              <JoditEditor
                                className="w-100 mb-3"
                                ref={editornewSectionWithoutImages}
                                value={_section.content || ""}
                                tabIndex={2}
                                onChange={(newContent) => {
                                  if (i18n.language === "en") {
                                    newSectionWithoutImages[_index].content =
                                      newContent;
                                  } else {
                                    newSectionWithoutImages[_index].content_ar =
                                      newContent;
                                  }
                                  setNewSectionWithoutImages([
                                    ...newSectionWithoutImages,
                                  ]);
                                }}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  width: "100%",
                                  alignItems: "center",
                                  backgroundImage: _section.backgroundImage
                                    ? `url(${
                                        url + "/" + _section.backgroundImage
                                      })`
                                    : "none", // Apply background image if selected
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  padding: "20px",
                                  borderRadius: "8px",
                                }}
                              >
                                {/* <CKEditor
                                  editor={ClassicEditor}
                                  data={_section.content}
                                  onChange={(event, editor) => {
                                    if (i18n.language === "en") {
                                      newSectionWithoutImages[_index].content =
                                        editor.getData();
                                    } else {
                                      newSectionWithoutImages[
                                        _index
                                      ].content_ar = editor.getData();
                                    }
                                    setNewSectionWithoutImages([
                                      ...newSectionWithoutImages,
                                    ]);
                                  }}
                                /> */}

                                <label className="form-label w-100">
                                  Background Image
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(ev) =>
                                      handleBackgroundImageChange(
                                        _index,
                                        ev,
                                        false
                                      )
                                    }
                                  />
                                </label>
                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    deleteNewSectionWithoutImages(_index)
                                  }
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "11vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={addNewSectionWithoutImages}
                      >
                        Add New Section
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "grid")}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="slug" className="form-label mt-3">
                        Grid Layout
                      </label>

                      <div style={{ marginBottom: "20px" }}>
                        <label htmlFor="backgroundImage" className="form-label">
                          Select Background Image for Section
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleGridBackgroundImageChange}
                        />
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          backgroundImage: gridBgImage
                            ? `url(${url + "/" + gridBgImage})`
                            : "none",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "10px",
                        }}
                      >
                        {grid &&
                          grid.map((_section, _index) => (
                            <div className="mb-3">
                              {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  placeholder="Enter label"
                                  type="text"
                                  className="form-control"
                                  id="titleAr"
                                  name="titleAr"
                                  // value={_section.content}
                                  onChange={(ev) =>
                                    handleGridLabelChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  placeholder="Enter Description"
                                  type="text"
                                  className="form-control"
                                  id="description_ar"
                                  name="description_ar"
                                  onChange={(ev) =>
                                    handleGridDescriptionChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  placeholder="Select Image"
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  // value={_section.image}
                                  onChange={(ev) => uploadGridImage(ev, _index)}
                                  required
                                />

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => deleteGridElemment(_index)}
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "15vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addGridElement}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          Add New Grid Element
                        </div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "slider")}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="metaTitleEn" className="form-label">
                        Form URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="formUrl"
                        name="formUrl"
                        value={formData.formUrl}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display As Landing Page</label>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            name="insurance"
                            type="checkbox"
                            checked={isLandingPage}
                            onChange={() => {
                              setIsLandingPage(!isLandingPage);
                            }}
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display Form</label>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            name="insurance"
                            type="checkbox"
                            checked={isForm}
                            onChange={() => {
                              setIsForm(!isForm);
                            }}
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display Back Button</label>
                      <div className="col-12">
                        <div className="form-check">
                          <label className="form-check-label">
                            {" "}
                            <input
                              name="backBtn"
                              type="checkbox"
                              checked={isBackBtn}
                              onChange={() => {
                                setIsBackBtn(!isBackBtn);
                              }}
                              className="form-check-input"
                            />
                            Yes{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div dir="rtl">
                      <select
                        style={{
                          padding: "5px",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                        onChange={(e) => handleOrderChange(e, "slider")}
                      >
                        <option value="" selected disabled>
                          Select Position
                        </option>
                        {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                          <option
                            key={i}
                            value={e}
                            disabled={getDisabledOptions("slider").includes(e)}
                          >
                            Position {e + 1}
                          </option>
                        ))}
                      </select>
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label ">
                          Slider
                        </label>
                        {sliderImages &&
                          sliderImages.map((_file, _index) => (
                            <div className="mb-3">
                              <label htmlFor="slug" className="form-label mt-3">
                                Image
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  value={_file.image_ar}
                                  onChange={(ev) => fileUpload(ev, _index)}
                                  required
                                />
                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => deleteFile(_index)}
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "10vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewImage}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Image
                          </div>
                        </div>
                      </div>
                      <select
                        style={{
                          padding: "5px",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                        onChange={(e) =>
                          handleOrderChange(e, "newSectionWithImages")
                        }
                      >
                        <option value="" selected disabled>
                          Select Position
                        </option>
                        {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                          <option
                            key={i}
                            value={e}
                            disabled={getDisabledOptions("slider").includes(e)}
                          >
                            Position {e + 1}
                          </option>
                        ))}
                      </select>
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label mt-3">
                          Add New Section With Image
                        </label>
                        {newSectionWithImages &&
                          newSectionWithImages.map((_section, _index) => (
                            <div className="mb-3">
                              {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {/* <CKEditor
                                  dir="rtl"
                                  editor={ClassicEditor}
                                  data=""
                                  config={{
                                    language: {
                                      ui: "ar", // UI language
                                      content: "ar", // Content language
                                    },
                                    contentsLangDirection: "rtl", // Content direction
                                    toolbar: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "|",
                                      "link",
                                      "bulletedList",
                                      "numberedList",
                                      "|",
                                      "indent",
                                      "outdent",
                                      "|",
                                      "imageUpload",
                                      "blockQuote",
                                      "insertTable",
                                      "mediaEmbed",
                                      "|",
                                      "undo",
                                      "redo",
                                      "|",
                                      "selectAll",
                                    ],
                                    heading: {
                                      options: [
                                        {
                                          model: "paragraph",
                                          title: "Paragraph",
                                          class: "ck-heading_paragraph",
                                        },
                                        {
                                          model: "heading1",
                                          view: "h1",
                                          title: "Heading 1",
                                          class: "ck-heading_heading1",
                                        },
                                        {
                                          model: "heading2",
                                          view: "h2",
                                          title: "Heading 2",
                                          class: "ck-heading_heading2",
                                        },
                                        {
                                          model: "heading3",
                                          view: "h3",
                                          title: "Heading 3",
                                          class: "ck-heading_heading3",
                                        },
                                        {
                                          model: "heading4",
                                          view: "h4",
                                          title: "Heading 4",
                                          class: "ck-heading_heading4",
                                        },
                                        {
                                          model: "heading5",
                                          view: "h5",
                                          title: "Heading 5",
                                          class: "ck-heading_heading5",
                                        },
                                        {
                                          model: "heading6",
                                          view: "h6",
                                          title: "Heading 6",
                                          class: "ck-heading_heading6",
                                        },
                                      ],
                                    },
                                  }}
                                  onChange={(event, editor) => {
                                    newSectionWithImages[_index].content_ar =
                                      editor.getData();
                                    // setFieldValue("description", editor.getData());
                                  }}
                                /> */}

                                <JoditEditor
                                  ref={editorNewSectionWithImage}
                                  tabIndex={2}
                                  onChange={(newContent) => {
                                    newSectionWithImages[_index].content_ar =
                                      newContent;
                                  }}
                                />

                                <input
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  value={_section.image}
                                  onChange={(ev) =>
                                    uploadSectionImage(ev, _index)
                                  }
                                  required
                                />

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    deleteNewSectionWithImages(_index)
                                  }
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "11vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewSectionWithImages}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Section
                          </div>
                        </div>
                      </div>
                      <select
                        style={{
                          padding: "5px",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                        onChange={(e) =>
                          handleOrderChange(e, "newSectionWithOutImages")
                        }
                      >
                        <option value="" selected disabled>
                          Select Position
                        </option>
                        {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                          <option
                            key={i}
                            value={e}
                            disabled={getDisabledOptions("slider").includes(e)}
                          >
                            Position {e + 1}
                          </option>
                        ))}
                      </select>
                      <div className="card mb-3 p-3">
                        <div className="mt-3">
                          <label htmlFor="slug" className="form-label ">
                            Add New Section Without Image
                          </label>
                          {newSectionWithoutImages &&
                            newSectionWithoutImages.map((_section, _index) => (
                              <div className="mb-3">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1%",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <CKEditor
                                    editor={ClassicEditor}
                                    data=""
                                    onChange={(event, editor) => {
                                      newSectionWithoutImages[
                                        _index
                                      ].content_ar = editor.getData();
                                      // setFieldValue("description", editor.getData());
                                    }}
                                    config={{
                                      language: {
                                        ui: "ar", // UI language
                                        content: "ar", // Content language
                                      },
                                      contentsLangDirection: "rtl", // Content direction
                                      toolbar: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "|",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "|",
                                        "indent",
                                        "outdent",
                                        "|",
                                        "imageUpload",
                                        "blockQuote",
                                        "insertTable",
                                        "mediaEmbed",
                                        "|",
                                        "undo",
                                        "redo",
                                        "|",
                                        "selectAll",
                                      ],
                                      heading: {
                                        options: [
                                          {
                                            model: "paragraph",
                                            title: "Paragraph",
                                            class: "ck-heading_paragraph",
                                          },
                                          {
                                            model: "heading1",
                                            view: "h1",
                                            title: "Heading 1",
                                            class: "ck-heading_heading1",
                                          },
                                          {
                                            model: "heading2",
                                            view: "h2",
                                            title: "Heading 2",
                                            class: "ck-heading_heading2",
                                          },
                                          {
                                            model: "heading3",
                                            view: "h3",
                                            title: "Heading 3",
                                            class: "ck-heading_heading3",
                                          },
                                          {
                                            model: "heading4",
                                            view: "h4",
                                            title: "Heading 4",
                                            class: "ck-heading_heading4",
                                          },
                                          {
                                            model: "heading5",
                                            view: "h5",
                                            title: "Heading 5",
                                            class: "ck-heading_heading5",
                                          },
                                          {
                                            model: "heading6",
                                            view: "h6",
                                            title: "Heading 6",
                                            class: "ck-heading_heading6",
                                          },
                                        ],
                                      },
                                    }}
                                  /> */}

                                  <JoditEditor
                                    ref={editornewSectionWithoutImages}
                                    value={_section.content || ""}
                                    tabIndex={2}
                                    onChange={(newContent) => {
                                      newSectionWithoutImages[
                                        _index
                                      ].content_ar = newContent;
                                    }}
                                  />

                                  <div
                                    style={{
                                      padding: "1%",
                                      height: "2vw",
                                      width: "2vw",
                                      backgroundColor: "red",
                                      borderRadius: "50%",
                                      color: "white",
                                      display: "flex",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      deleteNewSectionWithoutImages(_index)
                                    }
                                  >
                                    &times;
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "11vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewSectionWithoutImages}
                        >
                          Add New Section
                        </div>
                      </div>
                      <select
                        style={{
                          padding: "5px",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                        onChange={(e) => handleOrderChange(e, "grid")}
                      >
                        <option value="" selected disabled>
                          Select Position
                        </option>
                        {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                          <option
                            key={i}
                            value={e}
                            disabled={getDisabledOptions("slider").includes(e)}
                          >
                            Position {e + 1}
                          </option>
                        ))}
                      </select>
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label mt-3">
                          Grid Layout
                        </label>
                        {grid &&
                          grid.map((_section, _index) => (
                            <div className="mb-3">
                              {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  dir="rtl"
                                  placeholder="Enter label"
                                  type="text"
                                  className="form-control"
                                  id="titleAr"
                                  name="titleAr"
                                  value={_section.content}
                                  onChange={(ev) =>
                                    handleGridLabelChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  placeholder="Enter Description"
                                  type="text"
                                  className="form-control"
                                  id="description_ar"
                                  name="description_ar"
                                  onChange={(ev) =>
                                    handleGridDescriptionChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  placeholder="Select Image"
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  value={_section.image}
                                  onChange={(ev) => uploadGridImage(ev, _index)}
                                  required
                                />

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => deleteGridElemment(_index)}
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "15vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addGridElement}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Grid Element
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display As Landing Page</label>
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              name="insurance"
                              type="checkbox"
                              checked={isLandingPage}
                              onChange={() => {
                                setIsLandingPage(!isLandingPage);
                              }}
                              className="form-check-input"
                            />
                            <label className="form-check-label"> Yes </label>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display Form</label>
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              name="insurance"
                              type="checkbox"
                              checked={isForm}
                              onChange={() => {
                                setIsForm(!isForm);
                              }}
                              className="form-check-input"
                            />
                            <label className="form-check-label"> Yes </label>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display Back Button</label>
                        <div className="col-12">
                          <div className="form-check">
                            <label className="form-check-label">
                              {" "}
                              <input
                                name="backBtn"
                                type="checkbox"
                                checked={isBackBtn}
                                onChange={() => {
                                  setIsBackBtn(!isBackBtn);
                                }}
                                className="form-check-input"
                              />
                              Yes{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="mb-3 mt-3">
                  <label htmlFor="slug" className="form-label">
                    Parent Slug
                  </label>
                  <select
                    className="form-control"
                    value={formData?.parentSlug}
                    name="parentSlug"
                    onChange={handleChange}
                  >
                    <option disabled>Select Parent Slug</option>
                    {parentSlugList &&
                      Array.isArray(parentSlugList) &&
                      parentSlugList.length > 0 &&
                      parentSlugList?.map((s, i) => (
                        <option key={i} value={s}>
                          {s}
                        </option>
                      ))}
                    <option value="">None</option>
                  </select>
                </div>

                <div className="mb-3 mt-3">
                  <label htmlFor="slug" className="form-label">
                    Slug
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    name="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    required
                  />
                </div>

                {activeLanguage === "en" ? (
                  <>
                    <div className="mb-3">
                      <label htmlFor="titleEn" className="form-label">
                        Title (English)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="titleEn"
                        name="titleEn"
                        value={formData.titleEn}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="descriptionEn" className="form-label">
                        Description (English)
                      </label>
                      {/* <CKEditor
                        config={{
                          language: "en",
                          contentsLangDirection:
                            activeLanguage === "ar" ? "rtl" : "ltr",
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "imageUpload",
                            "blockQuote",
                            "insertTable",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectAll",
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                        }}
                        editor={ClassicEditor}
                        data={formData.descriptionEn}
                        onChange={(event, editor) =>
                          handleEditorChange(editor, "en")
                        }
                      /> */}

                      <JoditEditor
                        ref={editor}
                        value={formData.descriptionEn || ""}
                        tabIndex={1}
                        onChange={(newContent) =>
                          handleEditorChange(newContent, "en")
                        }
                        // onBlur={(newContent) =>
                        //   handleEditorChange(newContent, "en")
                        // }
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="metaTitleEn" className="form-label">
                        Meta Title (English)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="metaTitleEn"
                        name="metaTitleEn"
                        value={formData.metaTitleEn}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaDescriptionEn" className="form-label">
                        Meta Description (English)
                      </label>
                      <textarea
                        className="form-control"
                        id="metaDescriptionEn"
                        name="metaDescriptionEn"
                        rows="2"
                        value={formData.metaDescriptionEn}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaKeywordsEn" className="form-label">
                        Meta Keywords (English)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="metaKeywordsEn"
                        name="metaKeywordsEn"
                        value={formData.metaKeywordsEn}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaKeywordsEn" className="form-label">
                        Back Button Text
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="backBtnText"
                        name="backBtnText"
                        value={formData.backBtnText}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaKeywordsEn" className="form-label">
                        Redirect URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="redirectUrl"
                        name="redirectUrl"
                        value={formData.redirectUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3" dir="rtl">
                      <label htmlFor="titleAr" className="form-label">
                        Title (Arabic)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="titleAr"
                        name="titleAr"
                        dir="rtl"
                        value={formData.titleAr}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 " dir="rtl">
                      <label htmlFor="descriptionAr" className="form-label">
                        Description (Arabic)
                      </label>
                      <JoditEditor
                        ref={editor}
                        value={formData.descriptionAr || ""}
                        tabIndex={2}
                        onChange={(newContent) =>
                          handleEditorChange(newContent, "ar")
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaTitleEn" className="form-label">
                        Form URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="formUrl"
                        name="formUrl"
                        value={formData.formUrl}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3" dir="rtl">
                      <label htmlFor="metaTitleAr" className="form-label">
                        Meta Title (Arabic)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="metaTitleAr"
                        name="metaTitleAr"
                        dir="rtl"
                        value={formData.metaTitleAr}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3" dir="rtl">
                      <label htmlFor="metaDescriptionAr" className="form-label">
                        Meta Description (Arabic)
                      </label>
                      <textarea
                        className="form-control"
                        id="metaDescriptionAr"
                        name="metaDescriptionAr"
                        rows="2"
                        dir="rtl"
                        value={formData.metaDescriptionAr}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3" dir="rtl">
                      <label htmlFor="metaKeywordsAr" className="form-label">
                        Meta Keywords (Arabic)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        dir="rtl"
                        id="metaKeywordsAr"
                        name="metaKeywordsAr"
                        value={formData.metaKeywordsAr}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaKeywordsEn" className="form-label">
                        Back Button Text (Arabic)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="backBtnText_ar"
                        name="backBtnText_ar"
                        value={formData.backBtnText_ar}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="metaKeywordsEn" className="form-label">
                        Redirect URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="redirectUrl"
                        name="redirectUrl"
                        value={formData.redirectUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Create Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePageForm;
