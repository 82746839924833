import { useCallback, useEffect, useState } from "react";
import "./Page.css";
import { url } from "../Components/Common/Url";
import axios from "axios";
import pages from "../assets/pages.png";
import {
  FaWpforms,
  FaRegTrashAlt,
  FaCopy,
  FaClipboardCheck,
} from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

const ListForms = () => {
  const USER_DOMAIN_URL = "asterclinicmern.stldigitaltech.com";
  const [isCopiedId, setIsCopiedId] = useState(null);
  const [droppedItems, setDroppedItems] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [resizingItem, setResizingItem] = useState(null);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [forms, setForms] = useState([]);
  const [pageData, setPageData] = useState({
    pageName: "",
    picture: "",
    buttonText: "",
    heading: "",
    headingFontSize: 26,
    description: "",
    text: { heading: "Text", value: "" },
    textarea: { heading: "Details", value: "" },
    number: { heading: "Number", value: "" },
    mobile: { heading: "Mobile", countryCode: "", value: "" },
    email: { heading: "Email", value: "" },
    file: { heading: "Upload File", value: null },
    image: { heading: "Upload Image", value: null },
    dropdown: {
      heading: "Select",
      value: null,
      required: false,
      dropdownOptions: [],
    },
    country: {
      heading: "Select Country",
      selectedCountry: "",
      required: false,
    },
    radio: {
      heading: "Choose an option",
      value: "",
      required: false,
      radioOptions: [],
    },
    date: { heading: "Date", value: "" },
    time: { heading: "Time", value: "" },
  });
  const [formData, setFormData] = useState({});
  const [messageStatus, setMessageStatus] = useState("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    const fetchCaptcha = async () => {
      try {
        const response = await fetch(`${url}/captcha/generate`);
        const data = await response.json();
        setCaptchaImage(data.captchaImage);
      } catch (error) {
        console.error("Error loading CAPTCHA:", error);
      }
    };

    fetchCaptcha();
  }, []);

  useEffect(() => {
    fetchForms();
  }, []);

  const handleChangeFormData = (componentId, fieldName, value) => {
    setFormData((prevData) => {
      const currentComponentData = prevData[componentId] || {};
      return {
        ...prevData,
        [componentId]: {
          ...currentComponentData,
          [fieldName]: value,
        },
      };
    });
  };

  const fetchForms = async () => {
    try {
      const formResponse = await axios.get(`${url}/api/v1/form-builder/list`);
      if (formResponse.data) {
        setForms(formResponse.data);
      }
    } catch (error) {
      console.error(
        "Unable to fetch forms. Please try again later.",
        error.message
      );
    }
  };

  const handleChange = (field, value) => {
    const keys = field.split(".");
    setPageData((prevState) => {
      const newState = { ...prevState };
      let temp = newState;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          temp = temp[key] = { ...temp[key] };
        }
      });
      return newState;
    });
  };

  const handleDragStart = (event, component, type = "component") => {
    const data = JSON.stringify({ component, type });
    event.dataTransfer.setData("component", data);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("component");
    if (data) {
      try {
        const { component, type } = JSON.parse(data);
        const newItem = {
          ...component,
          id: `${component.id || "image"}-${Date.now()}`,
          type,
          width: 200,
          height: 200,
        };
        setDroppedItems([...droppedItems, newItem]);
      } catch (error) {
        console.error("Error parsing dropped data:", error);
      }
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDelete = (id) => {
    setDroppedItems(droppedItems.filter((item) => item.id !== id));
  };

  const handleDragItemStart = (index) => {
    setDraggedIndex(index);
  };

  const handleItemDrop = (event, dropIndex) => {
    event.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    const reorderedItems = [...droppedItems];
    const [draggedItem] = reorderedItems.splice(draggedIndex, 1);
    reorderedItems.splice(dropIndex, 0, draggedItem);

    setDroppedItems(reorderedItems);
    setDraggedIndex(null);
  };

  const startResizing = (e, itemId) => {
    e.preventDefault();
    setResizingItem(itemId);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const resizeItem = useCallback(
    (e) => {
      if (!resizingItem) return;

      const dx = e.clientX - startPosition.x;
      const dy = e.clientY - startPosition.y;

      const scaleFactor = 0.1;

      setDroppedItems((prev) =>
        prev.map((item) =>
          item.id === resizingItem
            ? {
                ...item,
                width: Math.max(50, item.width + dx * scaleFactor),
                height: Math.max(50, item.height + dy * scaleFactor),
              }
            : item
        )
      );
      setStartPosition({ x: e.clientX, y: e.clientY });
    },
    [resizingItem, startPosition]
  );

  useEffect(() => {
    document.addEventListener("mousemove", resizeItem);
    document.addEventListener("mouseup", stopResizing);

    return () => {
      document.removeEventListener("mousemove", resizeItem);
      document.removeEventListener("mouseup", stopResizing);
    };
  }, [resizeItem]);

  const stopResizing = () => {
    setResizingItem(null);
  };

  const handleHeadingDragStart = (event) => {
    const heading = {
      id: "heading-" + Date.now(),
      type: "heading",
      content: pageData.heading,
      fontSize: pageData.headingFontSize,
    };
    const data = JSON.stringify({ component: heading, type: "heading" });
    event.dataTransfer.setData("component", data);
  };

  const handleDescriptionDragStart = (event) => {
    const description = {
      id: "description-" + Date.now(),
      type: "description",
      content: pageData.description,
    };
    const data = JSON.stringify({
      component: description,
      type: "description",
    });
    event.dataTransfer.setData("component", data);
  };

  const countryValidationRules = {
    "+1": { minLength: 10, maxLength: 10 },
    "+44": { minLength: 11, maxLength: 11 },
    "+91": { minLength: 10, maxLength: 10 },
    "+61": { minLength: 9, maxLength: 9 },
    "+49": { minLength: 10, maxLength: 11 },
    "+33": { minLength: 9, maxLength: 9 },
    "+86": { minLength: 11, maxLength: 11 },
    "+81": { minLength: 10, maxLength: 11 },
    "+55": { minLength: 10, maxLength: 11 },
    "+27": { minLength: 10, maxLength: 10 },
    "+52": { minLength: 10, maxLength: 10 },
    "+7": { minLength: 10, maxLength: 10 },
    "+92": { minLength: 10, maxLength: 10 },
    "+65": { minLength: 8, maxLength: 8 },
    "+39": { minLength: 10, maxLength: 10 },
    "+20": { minLength: 11, maxLength: 11 },
    "+90": { minLength: 10, maxLength: 10 },
    "+54": { minLength: 10, maxLength: 10 },
    "+234": { minLength: 11, maxLength: 11 },
    "+82": { minLength: 10, maxLength: 11 },
  };

  const validateMobileNumber = (number, countryCode) => {
    const { minLength, maxLength } = countryValidationRules[countryCode] || {
      minLength: 8,
      maxLength: 11,
    };
    return number.length >= minLength && number.length <= maxLength;
  };

  const getMinLength = (countryCode) => {
    return countryValidationRules[countryCode]?.minLength || 8;
  };

  const getMaxLength = (countryCode) => {
    return countryValidationRules[countryCode]?.maxLength || 11;
  };

  const countryCodes = [
    "+54",
    "+61",
    "+43",
    "+32",
    "+55",
    "+359",
    "+1-242",
    "+1-876",
    "+1",
    "+971",
    "+44",
    "+91",
    "+33",
    "+49",
    "+34",
    "+39",
    "+81",
    "+7",
    "+86",
    "+20",
    "+63",
    "+90",
    "+351",
    "+48",
    "+41",
    "+46",
    "+45",
    "+64",
    "+27",
    "+82",
    "+66",
    "+55",
    "+65",
    "+32",
    "+58",
    "+30",
    "+34",
    "+56",
    "+45",
    "+27",
  ];

  const getCountryName = (code) => {
    const countryNames = {
      "+54": "Argentina",
      "+61": "Australia",
      "+43": "Austria",
      "+32": "Belgium",
      "+55": "Brazil",
      "+359": "Bulgaria",
      "+1-242": "Bahamas",
      "+1-876": "Jamaica",
      "+1": "USA",
      "+971": "United Arab Emirates",
      "+44": "UK",
      "+91": "India",
      "+33": "France",
      "+49": "Germany",
      "+34": "Spain",
      "+39": "Italy",
      "+81": "Japan",
      "+7": "Russia",
      "+86": "China",
      "+20": "Egypt",
      "+63": "Philippines",
      "+90": "Turkey",
      "+351": "Portugal",
      "+48": "Poland",
      "+41": "Switzerland",
      "+46": "Sweden",
      "+45": "Denmark",
      "+64": "New Zealand",
      "+27": "South Africa",
      "+82": "South Korea",
      "+66": "Thailand",
      "+65": "Singapore",
      "+58": "Venezuela",
      "+30": "Greece",
      "+56": "Chile",
    };

    return countryNames[code] || "Unknown Country";
  };

  const countryNames = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleSaveFormData = async (e, item) => {
    e.preventDefault();

    if (!captchaToken) {
      alert("Google reCAPTCHA not verified!");
      return;
    }

    try {
      const formResponse = await fetch(`${url}//api/v1/form-builder/submit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          formName: item.formName,
          captchaInput,
          captchaToken,
          formData,
        }),
      });

      if (formResponse.status === 201) {
        setMessageStatus(`${item.formName} submitted successfully!`);
        setFormData({});
        setCaptchaToken(null);
        setCaptchaInput("");
      } else {
        const responseData = await formResponse.json();
        alert(`Error: ${responseData.message || "Form submission failed"}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error while submitting the form. Please try again.");
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
    setCaptchaVerified(true);
  };

  const createPage = async () => {
    if (!pageData.pageName || pageData.pageName.trim() === "") {
      alert("Please enter a page name.");
      return;
    }

    if (!droppedItems || droppedItems.length === 0) {
      alert("Please add at least one component.");
      return;
    }

    try {
      const processDroppedItems = async () => {
        return Promise.all(
          droppedItems.map(async (item) => {
            if (
              item.type === "image" &&
              item.picture &&
              item.picture.startsWith("blob:")
            ) {
              const imageFile = await fetch(item.picture)
                .then((res) => res.blob())
                .then((blob) => {
                  return new File([blob], `${item.id}.jpg`, {
                    type: blob.type,
                  });
                });

              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                  if (reader.result) {
                    item.picture = reader.result;
                    resolve(item);
                  } else {
                    reject(new Error("Failed to convert image to base64"));
                  }
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(imageFile);
              });
            }
            return item;
          })
        );
      };

      const processedItems = await processDroppedItems();

      const response = await axios.post(`${url}/api/v1/page-builder/create`, {
        pageName: pageData.pageName,
        droppedItems: processedItems,
      });

      if (response.data) {
        alert("Page created successfully!");
        setPageData({
          pageName: "",
          picture: "",
          buttonText: "",
          heading: "",
          headingFontSize: 26,
          description: "",
          text: "",
          textarea: "",
          number: { heading: "", value: "" },
          mobile: { heading: "", countryCode: "", value: "" },
          email: { heading: "Enter Email", value: "" },
          file: { heading: "Upload File", value: null },
          image: { heading: "Upload Image", value: null },
          dropdownOptions: [{ id: Date.now(), text: "" }],
          country: { heading: "Select Country", selectedCountry: "" },
          checkBox: { heading: "Check this box", selected: false },
          radio: { heading: "Choose an option", selected: "" },
          date: "",
          time: "",
        });
        setDroppedItems([]);
      }
    } catch (error) {
      console.error("Error creating page:", error.message);
      alert("Unable to create the page. Please try again later.");
    }
  };

  const copyForm = (id, thankyou_url) => {
    setIsCopiedId(id);
    navigator.clipboard.writeText(
      `https://${USER_DOMAIN_URL}/form/${id}?redirect_to=https://${USER_DOMAIN_URL}/${thankyou_url}`
    );
    setTimeout(() => {
      setIsCopiedId(null);
    }, 1000);
  };

  return (
    <div className="page-container">
      <div className="page-list-top">
        <div className="page-list-heading">
          <img src={pages} alt="" />
          <h1>Pages</h1>
          <span>Pages</span>
        </div>
      </div>
      <div className="dynamic-page-container">
        <div className="page-component-panel">
          <div className="form-list-container">
            <h3>Forms</h3>
            {forms.map((form, index) => (
              <div
                key={index}
                className="form-draggable-item"
                draggable
                onDragStart={(e) => handleDragStart(e, form)}
              >
                <div className="form-component-icon-label">
                  <FaWpforms />
                  <span>{form.formName}</span>
                </div>
                <button
                  className="copy-icon"
                  onClick={() => {
                    console.log(form);
                    copyForm(form?._id, form?.thankyou_url);
                  }}
                >
                  {" "}
                  {isCopiedId === form?._id ? <FaClipboardCheck /> : <FaCopy />}
                </button>
              </div>
            ))}
          </div>
        </div>
        {/* <div
          className="page-drop-container"
          onDrop={handleDrop}
          onDragOver={allowDrop}
        >
          <div className="page-drop-container-heading">
            <div className="page-drop-heading-left">
              <h4>Page Name:</h4>
              <input
                type="text"
                placeholder="Enter page name..."
                value={pageData.pageName}
                onChange={(e) => handleChange("pageName", e.target.value)}
                required
              />
            </div>
            <div className="page-drop-heading-right">
              <button className="primary-button" onClick={createPage}>
                <MdAdd /> Create Page
              </button>
            </div>
          </div>
          <hr />
          {droppedItems.length === 0 && (
            <p className="placeholder-text">
              Drag any component here from the left
            </p>
          )}

          {droppedItems.map((item, index) => (
            <div
              key={item.id}
              className={`page-dropped-item ${
                draggedIndex === index ? "dragging" : ""
              }`}
              draggable
              onDragStart={() => handleDragItemStart(index)}
              onDragOver={(e) => allowDrop(e)}
              onDrop={(e) => handleItemDrop(e, index)}
              style={{
                width: item.type === "image" ? `${item.width}px` : "100%",
                height: item.type === "image" ? `${item.height}px` : "auto",
                position: "relative",
              }}
            >
              {item.type === "image" ? (
                <>
                  <img
                    src={item.picture}
                    alt="dropped_img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <div
                    className="resize-handle"
                    style={{
                      width: "10px",
                      height: "10px",
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      backgroundColor: "blue",
                      cursor: "nwse-resize",
                    }}
                    onMouseDown={(e) => startResizing(e, item.id)}
                  ></div>
                  <FaRegTrashAlt
                    className="trash-icon"
                    onClick={() => handleDelete(item.id)}
                  />
                </>
              ) : item.type === "heading" ? (
                <>
                  <h1
                    style={{
                      fontSize: `${item.fontSize}px`,
                    }}
                  >
                    {item.content}
                  </h1>
                  <FaRegTrashAlt
                    className="trash-icon"
                    onClick={() => handleDelete(item.id)}
                  />
                </>
              ) : item.type === "description" ? (
                <>
                  <p>{item.content}</p>
                  <FaRegTrashAlt
                    className="trash-icon"
                    onClick={() => handleDelete(item.id)}
                  />
                </>
              ) : (
                <div className="page-component-form-content">
                  <h1>{item.formName}</h1>
                  <form
                    method="POST"
                    onSubmit={(e) => handleSaveFormData(e, item)}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <p>Please enter the CAPTCHA below:</p>
                        <img
                          src={captchaImage}
                          alt="CAPTCHA"
                          style={{ border: "1px solid #ccc", padding: "5px" }}
                        />
                      </div>
                      <input
                        type="text"
                        id="captcha-input"
                        value={captchaInput}
                        onChange={(e) => setCaptchaInput(e.target.value)}
                        placeholder="Enter CAPTCHA"
                        required
                      />
                    </div>

                    {item.components.map((component, index) => (
                      <div className="dragged-form" key={index}>
                        {component.type === "heading" && (
                          <h1 style={{ fontSize: `${item.headingFontSize}px` }}>
                            {component.heading || "Default Heading"}
                          </h1>
                        )}
                        {component.type === "description" && (
                          <p>
                            {component.description || "Default Description"}
                          </p>
                        )}
                        {component.type === "text" && (
                          <input
                            type="text"
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                newValue
                              );
                            }}
                            placeholder={component.heading}
                            required={component.required}
                          />
                        )}
                        {component.type === "textarea" && (
                          <textarea
                            rows={3}
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                newValue
                              );
                            }}
                            placeholder={component.heading}
                            required={component.required}
                          ></textarea>
                        )}
                        {component.type === "number" && (
                          <input
                            type="text"
                            name={`number-${component.id}`}
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                handleChangeFormData(
                                  component.heading,
                                  "value",
                                  value
                                );
                              }
                            }}
                            placeholder={component.heading}
                            required={component.required}
                          />
                        )}
                        {component.type === "dropdown" && (
                          <select
                            name={`dropdown-${component.id}`}
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                value
                              );
                            }}
                            required={component.required}
                          >
                            <option value="">{component.heading}</option>
                            {component.dropdownOptions.map(
                              (option, optIndex) => (
                                <option key={optIndex} value={option.text}>
                                  {option.text}
                                </option>
                              )
                            )}
                          </select>
                        )}
                        {component.type === "file" && (
                          <div>
                            <input
                              type="file"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                handleChangeFormData(
                                  component.heading,
                                  "value",
                                  file
                                );
                              }}
                            />
                            {formData[component.heading]?.value && (
                              <p>
                                Selected file:{" "}
                                {formData[component.heading]?.value.name}
                              </p>
                            )}
                          </div>
                        )}
                        {component.type === "radio" && (
                          <div className="page-radio-container">
                            <p>{component.heading}</p>
                            {component.radioOptions?.map((option) => (
                              <div
                                className="page-option-text-container"
                                key={option.id}
                              >
                                <input
                                  type="radio"
                                  id={`${component.id}-${option.id}`}
                                  name={component.heading}
                                  value={option.text}
                                  checked={
                                    formData[component.heading]?.value ===
                                    option.text
                                  }
                                  onChange={(e) => {
                                    handleChangeFormData(
                                      component.heading,
                                      "value",
                                      e.target.value
                                    );
                                  }}
                                  required={component.required}
                                />
                                <p>{option.text || "No text provided"}</p>
                              </div>
                            ))}
                          </div>
                        )}
                        {component.type === "date" && (
                          <input
                            type="date"
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                value
                              );
                            }}
                            placeholder={component.heading}
                            required={component.required}
                          />
                        )}
                        {component.type === "time" && (
                          <input
                            type="time"
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                value
                              );
                            }}
                            placeholder={component.heading}
                            required={component.required}
                          />
                        )}
                        {component.type === "mobile" && (
                          <div className="mobile-number-container">
                            <select
                              value={
                                formData[component.heading]?.countryCode || ""
                              }
                              onChange={(e) => {
                                const countryCode = e.target.value;
                                handleChangeFormData(
                                  component.heading,
                                  "countryCode",
                                  countryCode
                                );
                              }}
                              required={component.required}
                            >
                              <option value="">Select Country Code</option>
                              {countryCodes.map((code, index) => (
                                <option key={index} value={code}>
                                  {code} ({getCountryName(code)})
                                </option>
                              ))}
                            </select>

                            <input
                              type="text"
                              value={formData[component.heading]?.value || ""}
                              maxLength={getMaxLength(
                                formData[component.heading]?.countryCode || ""
                              )}
                              minLength={getMinLength(
                                formData[component.heading]?.countryCode || ""
                              )}
                              onKeyDown={(e) => {
                                if (
                                  e.key !== "Backspace" &&
                                  e.key !== "Delete" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight" &&
                                  !/^\d$/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const countryCode =
                                  formData[component.heading]?.countryCode;

                                if (/^\d*$/.test(newValue)) {
                                  handleChangeFormData(
                                    component.heading,
                                    "value",
                                    newValue
                                  );

                                  if (
                                    countryCode &&
                                    countryValidationRules[countryCode]
                                  ) {
                                    const isValid = validateMobileNumber(
                                      newValue,
                                      countryCode
                                    );
                                    if (!isValid) {
                                      console.error(
                                        "Invalid mobile number length for the selected country"
                                      );
                                    }
                                  }
                                }
                              }}
                              placeholder="Enter Mobile Number"
                              required={component.required}
                            />
                          </div>
                        )}
                        {component.type === "email" && (
                          <input
                            type="email"
                            value={formData[component.heading]?.value || ""}
                            onChange={(e) => {
                              const emailValue = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "value",
                                emailValue
                              );
                            }}
                            placeholder={component.heading}
                            style={{
                              borderColor: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                formData[component.heading]?.value || ""
                              )
                                ? "initial"
                                : "red",
                            }}
                            required={component.required}
                          />
                        )}
                        {component.type === "country" && (
                          <select
                            name={`country-${component.id}`}
                            value={
                              formData[component.heading]?.selectedCountry || ""
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChangeFormData(
                                component.heading,
                                "selectedCountry",
                                value
                              );
                            }}
                            required={component.required}
                          >
                            <option value="">{component.heading}</option>
                            {countryNames.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        )}
                        {component.type === "image" && (
                          <div className="image-show-container">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    const imageData = reader.result;
                                    handleChangeFormData(
                                      component.heading,
                                      "value",
                                      imageData
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              placeholder={component.heading}
                              required={component.required}
                            />
                            {formData[component.heading]?.value && (
                              <img
                                src={formData[component.heading]?.value}
                                alt="Preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        )}
                        {component.type === "button" && (
                          <div className="form-group">
                            <button
                              className="primary-button"
                              type="submit"
                              disabled={!captchaVerified}
                            >
                              {item.buttonText}
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    <div>
                      <ReCAPTCHA
                        sitekey="6LeWcqYqAAAAAE-QQUkYDT6J0lDx9900NfJ1Yh-I"
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    {messageStatus && <p>{messageStatus}</p>}
                  </form>
                  <FaRegTrashAlt
                    className="trash-icon"
                    onClick={() => handleDelete(item.id)}
                  />
                </div>
              )}
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ListForms;
