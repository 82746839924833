import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DashboardPage from "./Page/Home/DashboardPage";
import HeaderComponent from "./Components/Common/HeaderComponent";
import SideBarComponents from "./Components/Common/SideBarComponents";
import LoginPage from "./Page/Login/LoginPage";
import { useSelector } from "react-redux";
import AboutPage from "./Page/About/AboutPage";
import Doctor from "./Page/Doctor/Doctor";
import DoctorsList from "./Page/Doctor/view.jsx";
import EditDoctorPage from "./Page/Doctor/edit.jsx";

import Contactus from "./Page/Contactus/Contactus";
import Forms from "./Page/Forms/Forms";
import Excellance from "./Page/Excellance/Excellance";
import Specialities from "./Page/specialities";
import EditSpecialityForm from "./Forms/EditSpecialityForm.jsx";
import SpecialitiesList from "./Page/SpecialitiesList";
import ManageSpecialityFAQPage from "./Page/ManageSpecialityFAQPage.jsx";
import ManageFacilitiesPage from "./Page/ManageFacilitiesPage.jsx";
import ClinicPage from "./Page/Clinic/Create.jsx";
import Clinic from "./Page/Clinic/Clinic";
import ClinicsList from "./Page/Clinic/view.jsx";
import EditClinicPage from "./Page/Clinic/edit.jsx";

import Profile from "./Page/profile";
import ExcellanceList from "./Page/Excellance/ExcellanceList";
import SettingsPage from "./Page/Settings/SettingsPage";
import "react-toastify/dist/ReactToastify.css";
import Index from "./Page/AsterAtHome/Index";
import Description from "./Page/AsterAtHome/Description";
import About from "./Page/AsterAtHome/About";
import Notes from "./Page/AsterAtHome/Notes";
import Faq from "./Page/AsterAtHome/Faq";
import FaqList from "./Page/AsterAtHome/FaqList";
import Hotel from "./Page/AsterAtHome/Hotel";
import HotelList from "./Page/AsterAtHome/HotelList";
import Office from "./Page/AsterAtHome/Office";
import OfficeList from "./Page/AsterAtHome/OfficeList";
import Treatment from "./Page/AsterAtHome/Treatment";
import Slider from "./Page/AsterAtHome/Slider";
import Service from "./Page/AsterAtHome/Service";
import IndexPage from "./Page/Teleconsultation/IndexPage.jsx";
import Step from "./Page/Teleconsultation/Step.jsx";
import Trust from "./Page/Teleconsultation/Trust.jsx";
import AddCategory from "./Page/Blog/Category/AddCategory.jsx";
import CategoryListComponent from "./Components/Blog/Category/CategoryListComponent.jsx";
import AddBlog from "./Page/Blog/Category/AddBlog.jsx";
import BlogListPage from "./Page/Blog/Category/BlogListPage.jsx";
import AddTag from "./Page/Blog/Category/AddTag.jsx";
import CareersForm from "./Forms/CareersForm.jsx";
import CareerPage from "./Page/CareerPage.jsx";
import HealthToolPage from "./Page/PatientCare/HealthToolPage.jsx";
import BmiPage from "./Page/PatientCare/BmiPage.jsx";
import GerdRiskPage from "./Page/PatientCare/GerdRiskPage.jsx";
import SeniorCarePage from "./Page/PatientCare/SeniorCarePage.jsx";
import SeniorCareBenefitPage from "./Page/PatientCare/SeniorCareBenefitPage.jsx";
import SeniorCareOfferPage from "./Page/PatientCare/SeniorCareOfferPage.jsx";
import PhilosopyPage from "./Page/PatientCare/PhilosopyPage.jsx";
import SkinCarePage from "./Page/SkinCare/SkinCarePage.jsx";
import DoctorSpeakPage from "./Page/SkinCare/DoctorSpeakPage.jsx";
import Dermotology from "./Page/SkinCare/Dermotology.jsx";
import CounterPage from "./Page/SkinCare/CounterPage.jsx";
import VitaminPage from "./Page/Vitamin/VitaminPage.jsx";
import VitaminFaqPage from "./Page/Vitamin/VitaminFaqPage.jsx";
import VitaminWorkTherapy from "./Page/Vitamin/VitaminWorkTherapy.jsx";
import VitaminTherapyPage from "./Page/Vitamin/VitaminTherapyPage.jsx";
import VitaminCategoryPage from "./Page/Vitamin/VitaminCategoryPage.jsx";
import VitaminTherapyTypePage from "./Page/Vitamin/VitaminTherapyTypePage.jsx";
import AsterHospitalPage from "./Page/PatientCare/AsterHospitalPage.jsx";
import InsurancePage from "./Page/PatientCare/InsurancePage.jsx";
import EmotionalSliderPage from "./Page/PatientCare/EmotionalSliderPage.jsx";
import EmotionalDescription from "./Page/PatientCare/EmotionalDescription.jsx";
import SpecialClinicPage from "./Page/PatientCare/SpecialClinicPage.jsx";
import SpecialClinicTypePage from "./Page/PatientCare/SpecialClinicTypePage.jsx";
import FooterPage from "./Page/Footer/FooterPage.jsx";
import PreventHealthPage from "./Page/PatientCare/PreventHealthPage.jsx";
import PreventHealthDescription from "./Page/PatientCare/PreventHealthDescription.jsx";
import OffShoresPage from "./Page/PatientCare/OffShoresPage.jsx";
import DescriptionPage from "./Page/RtPcr/DescriptionPage.jsx";
import StepPage from "./Page/RtPcr/StepPage.jsx";
import TrustPage from "./Page/RtPcr/TrustPage.jsx";
import FaqPage from "./Page/RtPcr/FaqPage.jsx";
import PackagePage from "./Page/Wellness/PackagePage.jsx";
import ClientPage from "./Page/Wellness/ClientPage.jsx";
import ProgramPage from "./Page/Wellness/ProgramPage.jsx";
import TestimonialPage from "./Page/Wellness/TestimonialPage.jsx";
import DownloadPage from "./Page/Download/DownloadPage.jsx";
import SliderPage from "./Page/Wellness/SliderPage.jsx";
import SpecialServicePage from "./Page/SpecialService/SpecialServicePage.jsx";
import AddOffers from "./Components/Offers/AddOffers/AddOffers.jsx";
import Offfers from "./Components/Offers/AddOffers/Offers.jsx";
import List from "./Components/Offers/ListOffers/List.jsx";
import News from "./Components/News/AddNews/News.jsx";
import ListNews from "./Components/News/ListNews/List.jsx";
import AllNews from "./Components/News/ListNews/List.jsx";
import CreatePageForm from "./Page/CreatePageForm.jsx";
import ListPages from "./Page/ListPages.jsx";
import EditPageForm from "./Page/EditPageForm.jsx";
import HeaderPage from "./Page/Header/HeaderPage.jsx";
import CommentListPage from "./Page/CommentListPage.jsx";
import CreateForm from "./Page/CreateForm.jsx";
import ListForms from "./Page/ListForms.jsx";
import LeadForms from "./Page/LeadForms.jsx";

const Layout = () => {
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.user);
  useEffect(() => {
    if (token == null) {
      navigate("/");
    }
  }, [token]);
  return (
    <>
      <ToastContainer />
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideBarComponents />
          <div className="layout-page">
            <HeaderComponent />
            <div className="content-wrapper">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/admin",
    element: <Layout />,
    children: [
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "add-doctor",
        element: <Doctor />,
      },
      {
        path: "doctors-list",
        element: <DoctorsList />,
      },
      {
        path: "edit-doctor/:id",
        element: <EditDoctorPage />,
      },
      {
        path: "specialities",
        element: <Specialities />,
      },
      {
        path: "specialities-list",
        element: <SpecialitiesList />,
      },
      {
        path: "edit-specialty/:id",
        element: <EditSpecialityForm />,
      },
      {
        path: "manage-specialty-faq/:id",
        element: <ManageSpecialityFAQPage />,
      },
      {
        path: "manage-facilities",
        element: <ManageFacilitiesPage />,
      },
      {
        path: "add-clinic",
        element: <Clinic />,
      },
      {
        path: "edit-clinic/:id",
        element: <EditClinicPage />,
      },
      {
        path: "clinics-list",
        element: <ClinicsList />,
      },
      {
        path: "contact",
        element: <Contactus />,
      },
      {
        path: "forms",
        element: <Forms />,
      },
      {
        path: "careers",
        element: <CareerPage />,
      },
      {
        path: "excellance",
        element: <Excellance />,
      },
      {
        path: "download",
        element: <DownloadPage />,
      },
      {
        path: "download/:id",
        element: <DownloadPage />,
      },

      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "excellance/:id",
        element: <Excellance />,
      },
      {
        path: "asterathome/description",
        element: <Description />,
      },
      {
        path: "asterathome/about",
        element: <About />,
      },
      {
        path: "asterathome/notes",
        element: <Notes />,
      },
      {
        path: "asterathome/faq",
        element: <Faq />,
      },
      {
        path: "asterathome/faq/:id",
        element: <Faq />,
      },
      {
        path: "asterathome/faq/list",
        element: <FaqList />,
      },
      {
        path: "asterathome/athotel",
        element: <Hotel />,
      },
      {
        path: "asterathome/hotel/:id",
        element: <Hotel />,
      },
      {
        path: "asterathome/hotel/worklist",
        element: <HotelList />,
      },
      {
        path: "asterathome/office",
        element: <Office />,
      },
      {
        path: "asterathome/office/:id",
        element: <Office />,
      },
      {
        path: "asterathome/office/worklist",
        element: <OfficeList />,
      },
      {
        path: "asterathome/office/treatment",
        element: <Treatment />,
      },
      {
        path: "asterathome/slider",
        element: <Slider />,
      },
      {
        path: "asterathome/slider/:id",
        element: <Slider />,
      },
      {
        path: "asterathome/service",
        element: <Service />,
      },
      {
        path: "asterathome/service/:id",
        element: <Service />,
      },

      {
        path: "teleconsultation",
        element: <IndexPage />,
      },
      {
        path: "teleconsultation/step",
        element: <Step />,
      },
      {
        path: "teleconsultation/step/:id",
        element: <Step />,
      },

      {
        path: "teleconsultation/trust",
        element: <Trust />,
      },
      {
        path: "teleconsultation/trust/:id",
        element: <Trust />,
      },
      {
        path: "blog/category/add",
        element: <AddCategory />,
      },

      {
        path: "blog/tag/add",
        element: <AddTag />,
      },
      {
        path: "blog/tag/add/:id",
        element: <AddTag />,
      },

      {
        path: "blog/category/add/:id",
        element: <AddCategory />,
      },

      {
        path: "blog/add",
        element: <AddBlog />,
      },
      {
        path: "blog/add/:id",
        element: <AddBlog />,
      },
      {
        path: "blog",
        element: <BlogListPage />,
      },
      {
        path: "blog/comment-list",
        element: <CommentListPage />,
      },

      {
        path: "healthtool",
        element: <HealthToolPage />,
      },
      {
        path: "bmi",
        element: <BmiPage />,
      },
      {
        path: "gerdrisk",
        element: <GerdRiskPage />,
      },
      {
        path: "seniorcare",
        element: <SeniorCarePage />,
      },
      {
        path: "seniorcare/benefit",
        element: <SeniorCareBenefitPage />,
      },
      {
        path: "seniorcare/benefit/:id",
        element: <SeniorCareBenefitPage />,
      },

      {
        path: "seniorcare/offer",
        element: <SeniorCareOfferPage />,
      },
      {
        path: "seniorcare/offer/:id",
        element: <SeniorCareOfferPage />,
      },
      {
        path: "seniorcare/philosopy",
        element: <PhilosopyPage />,
      },
      {
        path: "seniorcare/philosopy/:id",
        element: <PhilosopyPage />,
      },
      {
        path: "skincare",
        element: <SkinCarePage />,
      },
      {
        path: "doctorspeak",
        element: <DoctorSpeakPage />,
      },
      {
        path: "doctorspeak/:id",
        element: <DoctorSpeakPage />,
      },
      {
        path: "dermotology",
        element: <Dermotology />,
      },
      {
        path: "dermotology/:id",
        element: <Dermotology />,
      },
      {
        path: "counter",
        element: <CounterPage />,
      },
      {
        path: "counter/:id",
        element: <CounterPage />,
      },

      {
        path: "vitamin",
        element: <VitaminPage />,
      },
      {
        path: "vitamin/faq",
        element: <VitaminFaqPage />,
      },
      {
        path: "vitamin/faq/:id",
        element: <VitaminFaqPage />,
      },

      {
        path: "vitamin/work",
        element: <VitaminWorkTherapy />,
      },

      {
        path: "vitamin/work/:id",
        element: <VitaminWorkTherapy />,
      },
      {
        path: "vitamin/therapy",
        element: <VitaminTherapyPage />,
      },
      {
        path: "vitamin/therapy/:id",
        element: <VitaminTherapyPage />,
      },

      {
        path: "vitamin/category",
        element: <VitaminCategoryPage />,
      },
      {
        path: "vitamin/category/:id",
        element: <VitaminCategoryPage />,
      },

      {
        path: "vitamin/therapy/type",
        element: <VitaminTherapyTypePage />,
      },

      {
        path: "vitamin/therapy/type/:id",
        element: <VitaminTherapyTypePage />,
      },
      {
        path: "aster/hospital",
        element: <AsterHospitalPage />,
      },
      {
        path: "insurance/affiliates",
        element: <InsurancePage />,
      },
      {
        path: "insurance/affiliates/:id",
        element: <InsurancePage />,
      },
      {
        path: "emotional/slider",
        element: <EmotionalSliderPage />,
      },
      {
        path: "emotional/slider/:id",
        element: <EmotionalSliderPage />,
      },

      {
        path: "emotional/description",
        element: <EmotionalDescription />,
      },

      {
        path: "special/clinic",
        element: <SpecialClinicPage />,
      },

      {
        path: "special/clinic/type",
        element: <SpecialClinicTypePage />,
      },

      {
        path: "special/clinic/type/:id",
        element: <SpecialClinicTypePage />,
      },
      {
        path: "health/package",
        element: <SpecialClinicTypePage />,
      },

      {
        path: "prevent/health/package",
        element: <PreventHealthPage />,
      },

      {
        path: "prevent/health/package/:id",
        element: <PreventHealthPage />,
      },

      {
        path: "prevent/health/description",
        element: <PreventHealthDescription />,
      },

      {
        path: "rtpcr/description",
        element: <DescriptionPage />,
      },

      {
        path: "rtpcr/step",
        element: <StepPage />,
      },
      {
        path: "rtpcr/step/:id",
        element: <StepPage />,
      },

      {
        path: "rtpcr/trust",
        element: <TrustPage />,
      },
      {
        path: "rtpcr/trust/:id",
        element: <TrustPage />,
      },
      {
        path: "rtpcr/faq",
        element: <FaqPage />,
      },
      {
        path: "rtpcr/faq/:id",
        element: <FaqPage />,
      },
      {
        path: "wellness/package",
        element: <PackagePage />,
      },
      {
        path: "wellness/package/:id",
        element: <PackagePage />,
      },

      {
        path: "special/service",
        element: <SpecialServicePage />,
      },
      {
        path: "special/service/:id",
        element: <SpecialServicePage />,
      },

      {
        path: "wellness/slider",
        element: <SliderPage />,
      },
      {
        path: "wellness/slider/:id",
        element: <SliderPage />,
      },

      {
        path: "wellness/client",
        element: <ClientPage />,
      },

      {
        path: "wellness/client/:id",
        element: <ClientPage />,
      },
      {
        path: "wellness/program",
        element: <ProgramPage />,
      },

      {
        path: "wellness/program/:id",
        element: <ProgramPage />,
      },
      {
        path: "wellness/testimonial",
        element: <TestimonialPage />,
      },
      {
        path: "wellness/testimonial/:id",
        element: <TestimonialPage />,
      },

      {
        path: "footer",
        element: <FooterPage />,
      },
      {
        path: "footer/:id",
        element: <FooterPage />,
      },

      {
        path: "header",
        element: <HeaderPage />,
      },
      {
        path: "header/:id",
        element: <HeaderPage />,
      },

      {
        path: "off-shore-medical-assessments",
        element: <OffShoresPage />,
      },

      {
        path: "excellance/view",
        element: <ExcellanceList />,
      },
      {
        path: "home",
        element: "",
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "offers/add",
        element: <Offfers />,
      },
      {
        path: "offers/add/:id",
        element: <Offfers />,
      },
      {
        path: "offers",
        element: <List />,
      },
      {
        path: "news/add",
        element: <News />,
      },
      {
        path: "news/add/:id",
        element: <News />,
      },
      {
        path: "news",
        element: <AllNews />,
      },
      {
        path: "create-page",
        element: <CreatePageForm />,
      },
      {
        path: "page-list",
        element: <ListPages />,
      },
      {
        path: "edit-page/:id",
        element: <EditPageForm />,
      },
      {
        path: "create-form",
        element: <CreateForm />,
      },
      {
        path: "form-list",
        element: <ListForms />,
      },
      {
        path: "lead-forms",
        element: <LeadForms />,
      },
    ],
  },
]);
export default function App() {
  return <RouterProvider router={router} />;
}
