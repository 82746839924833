import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component"; // Importing DataTable component
import { url } from "../Components/Common/Url";
import { useSelector } from "react-redux";

const CommentListPage = () => {
  const { token } = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${url}/api/v1/blog/comments/get/`, {
          headers: {
            token: `${token}`,
          },
        });
        setComments(response.data.comments);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch comments");
        setLoading(false);
      }
    };

    fetchComments();
  }, []);

  const handleApproval = async (commentId, approved) => {
    try {
      const updatedComment = await axios.patch(
        `${url}/api/v1/blog/comments/${commentId}`, // URL
        {
          approved: approved, // Data to update (approved status)
        },
        {
          headers: {
            token: `${token}`, // Authorization token
          },
        }
      );
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId ? updatedComment.data : comment
        )
      );
    } catch (err) {
      console.error("Failed to update approval status", err);
    }
  };

  const columns = [
    {
      name: "Comment",
      selector: (row) => row.comment,
      sortable: true,
    },
    {
      name: "Author",
      selector: (row) => row.author,
      sortable: true,
    },
    {
      name: "Blog",
      selector: (row) => row.blog.en_title,
      cell: (row) => (
        <a href="javascript:void(0)" style={{ color: "#DA4453" }}>
          {row.blog.en_title}
        </a>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.approved,
      cell: (row) => (
        <span
          className={`badge ${
            row.approved ? "bg-label-success" : "bg-label-danger"
          }`}
        >
          {row.approved ? "Approved" : "Pending"}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() => handleApproval(row._id, true)}
            disabled={row.approved}
          >
            Approve
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleApproval(row._id, false)}
            disabled={!row.approved}
          >
            Disapprove
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">All Comments</h5>
            <div className="card-body">
              <DataTable
                columns={columns}
                data={comments}
                pagination
                highlightOnHover
                striped
                responsive
                sortIcon={<i className="fas fa-sort" />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentListPage;
