import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { url } from "../Components/Common/Url";
import { useNavigate } from "react-router-dom";

const ListPages = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // Fetch Pages Data
  const fetchPages = async () => {
    try {
      setLoading(true); // Start loading
      const response = await axios.get(`${url}/api/v1/setting/pages`, {
        headers: {
          token: `${token}`,
        },
      });
      setPages(response.data); // Set the pages data
    } catch (err) {
      console.error("Error fetching pages:", err);
      setError("There was an error loading the pages.");
      toast.error("Error fetching pages!");
    } finally {
      setLoading(false); // End loading
    }
  };

  // UseEffect to Fetch Pages on Component Mount
  useEffect(() => {
    fetchPages();
  }, []);

  const handleDelete = async (pageId) => {
    try {
      const confirmation = window.confirm(
        "Are you sure you want to delete this page?"
      );
      if (confirmation) {
        const response = await axios.delete(
          `${url}/api/v1/setting/pages/${pageId}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        toast.success("Page deleted successfully!");
        // Re-fetch pages after deletion
        fetchPages();
      }
    } catch (error) {
      console.error("Error deleting page:", error);
      toast.error("There was an error deleting the page.");
    }
  };

  const handleEdit = (pageId) => {
    // Redirect to edit page or open modal for editing
    navigate(`/admin/edit-page/${pageId}`);
  };

  if (loading) {
    return (
      <div className="container-xxl flex-grow-1 container-p-y">
        <ToastContainer />
        <h3>Loading Pages...</h3>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container-xxl flex-grow-1 container-p-y">
        <ToastContainer />
        <h3>{error}</h3>
      </div>
    );
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div class="row card pt-3">
        <h3>Pages List</h3>
        <div className="table-responsive me-3">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Title (English)</th>
                <th>Title (Arabic)</th>
                <th>Slug</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pages.map((page) => (
                <tr key={page.id}>
                  <td>{page.titleEn}</td>
                  <td>{page.titleAr}</td>
                  <td>{page.slug}</td>
                  <td>
                    <button
                      onClick={() => handleEdit(page._id)}
                      className="btn btn-info btn-sm"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(page._id)}
                      className="btn btn-danger btn-sm ms-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div class="pt-3"></div>
      </div>
    </div>
  );
};

export default ListPages;
