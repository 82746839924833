import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "../i18n"; // Assuming i18n setup for language switching
import { url } from "../Components/Common/Url";
import { removeSpaceFromString } from "../utils/common";
import JoditEditor from "jodit-react";

const EditPageForm = () => {
  const editor = useRef(null);
  const editorNewSectionWithImage = useRef(null);
  const editornewSectionWithoutImages = useRef(null);
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.user);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sliderImages, setSliderImages] = useState([]);
  const [grid, setGrid] = useState([]);
  const [newSectionWithImages, setNewSectionWithImages] = useState([]);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [newSectionWithoutImages, setNewSectionWithoutImages] = useState([]);
  const [parentSlugList, setParentSlugList] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [isBackBtn, setIsBackBtn] = useState(false);
  const [formData, setFormData] = useState({
    parentSlug: "",
    slug: "",
    titleEn: "",
    titleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    metaTitleEn: "",
    metaDescriptionEn: "",
    metaKeywordsEn: "",
    metaTitleAr: "",
    metaDescriptionAr: "",
    metaKeywordsAr: "",
    formUrl: "",
    backBtnText_ar: "",
    backBtnText: "",
    redirectUrl: "",
  });

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(`${url}/api/v1/setting/pages/${id}`);

        // Check if response data exists and is structured correctly
        if (response && response.data) {
          // Update formData to include descriptions and other fields
          setFormData({
            parentSlug: response?.data?.parentSlug || "",
            slug: response.data.slug || "",
            titleEn: response.data.titleEn || "",
            titleAr: response.data.titleAr || "",
            descriptionEn: response.data.descriptionEn || "",
            descriptionAr: response.data.descriptionAr || "",
            metaTitleEn: response.data.metaTitleEn || "",
            metaDescriptionEn: response.data.metaDescriptionEn || "",
            metaKeywordsEn: response.data.metaKeywordsEn || "",
            metaTitleAr: response.data.metaTitleAr || "",
            metaDescriptionAr: response.data.metaDescriptionAr || "",
            metaKeywordsAr: response.data.metaKeywordsAr || "",
            formUrl: response?.data?.formUrl || "",
            backBtnText_ar: response?.data?.backBtnText_ar || "",
            backBtnText: response?.data?.backBtnText || "",
            redirectUrl: response?.data?.redirectUrl || "",
          });
          setSelectedOrder(response?.data?.selectedOrder);
          setIsBackBtn(response?.data?.isBackBtn || false);
          if (i18n.language === "en") {
            setGrid(response.data?.grid || []);
            setSliderImages(response.data?.sliderImages || []);
            setNewSectionWithImages(response.data?.newSectionWithImages || []);
            setNewSectionWithoutImages(
              response.data?.newSectionWithoutImages || []
            );
            setIsLandingPage(response?.data?.landingPage);
            setIsForm(response?.data?.form);
          } else {
            setGrid(response.data?.gridAr || []);
            setSliderImages(response.data?.sliderImagesAr || []);
            setNewSectionWithImages(
              response.data?.newSectionWithImagesAr || []
            );
            setNewSectionWithoutImages(
              response.data?.newSectionWithoutImagesAr || []
            );
            setIsLandingPage(response?.data?.landingPage);
            setIsForm(response?.data?.formAr);
          }
        }
      } catch (error) {
        console.error("Error fetching page data", error);
        toast.error("Failed to fetch page data");
      }
    };

    fetchPageData();
  }, [id, i18n.language]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEditorChange = (data, language) => {
    if (editor.current) {
      if (language === "en") {
        setFormData((prevState) => ({
          ...prevState,
          descriptionEn: data || "", // prevent null or undefined value
        }));
      } else if (language === "ar") {
        setFormData((prevState) => ({
          ...prevState,
          descriptionAr: data || "", // prevent null or undefined value
        }));
      }
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newFormdata = {
      ...formData,
      slug: removeSpaceFromString(formData?.slug),
      sliderImages,
      grid,
      newSectionWithImages,
      newSectionWithoutImages,
      landingPage: isLandingPage,
      language: i18n.language,
      form: isForm,
      selectedOrder,
      isBackBtn,
    };
    try {
      await axios.put(`${url}/api/v1/setting/pages/${id}`, newFormdata, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });
      toast.success("Page updated successfully!");
      navigate("/admin/page-list"); // Redirect after successful update
    } catch (error) {
      console.error("Error updating page:", error);
      toast.error("There was an error updating the page.");
    }
  };

  const fileUpload = async (ev, index) => {
    const file = ev.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        sliderImages[index].image = result.imagePath;
      } else {
        sliderImages[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
    console.log(sliderImages);
  };

  const deleteFile = (index) => {
    const filteredImages = sliderImages?.filter(
      (_image, _index) => _index !== index
    );
    setSliderImages(filteredImages);
  };

  const addNewImage = () => {
    setSliderImages([...sliderImages, { image: null, image_ar: null }]);
  };

  const uploadSectionImage = async (ev, index) => {
    const file = ev.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        newSectionWithImages[index].image = result.imagePath;
      } else {
        newSectionWithImages[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const deleteNewSectionWithImages = (index) => {
    const filteredSections = newSectionWithImages?.filter(
      (_image, _index) => _index !== index
    );
    setNewSectionWithImages(filteredSections);
  };

  const addNewSectionWithImages = () => {
    setNewSectionWithImages([
      ...newSectionWithImages,
      { content: "", content_ar: "", image: null, image_ar: null },
    ]);
  };

  const handleGridDescriptionChange = (ev, _index) => {
    const newGrid = [...grid];
    if (i18n.language === "en") {
      newGrid[_index].description = ev.target.value;
    } else {
      newGrid[_index].description_ar = ev.target.value;
    }
    setGrid(newGrid);
  };

  const deleteNewSectionWithoutImages = (index) => {
    const filteredSections = newSectionWithoutImages?.filter(
      (_image, _index) => _index !== index
    );
    setNewSectionWithoutImages(filteredSections);
  };

  const addNewSectionWithoutImages = () => {
    setNewSectionWithoutImages([
      ...newSectionWithoutImages,
      { content: "", content_ar: "" },
    ]);
  };
  const deleteGridElemment = (index) => {
    const filteredSections = grid?.filter((_image, _index) => _index !== index);
    setGrid(filteredSections);
  };
  const addGridElement = () => {
    setGrid([
      ...grid,
      { content: "", content: "", image: null, image_ar: null },
    ]);
  };
  const uploadGridImage = async (ev, index) => {
    const file = ev.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${url}/api/v1/setting/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );
      console.log(response);
      const result = response.data;
      if (i18n.language === "en") {
        grid[index].image = result.imagePath;
      } else {
        grid[index].image_ar = result.imagePath;
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  const handleGridLabelChange = (ev, _index) => {
    const newGrid = [...grid];
    if (i18n.language === "en") {
      newGrid[_index].content = ev.target.value;
    } else {
      newGrid[_index].content_ar = ev.target.value;
    }
    setGrid(newGrid);
  };

  const fetchPages = async () => {
    try {
      const response = await axios.get(`${url}/api/v1/setting/pages`, {
        headers: {
          token: `${token}`,
        },
      });
      const slugs = response?.data?.map((s) => s.slug);
      setParentSlugList(slugs || []);
    } catch (err) {
      console.error("Error fetching pages:", err);
    }
  };

  const [selectedOrder, setSelectedOrder] = useState({
    slider: 0,
    newSectionWithImages: 0,
    newSectionWithOutImages: 0,
    grid: 0,
    form: 0,
  });

  const handleOrderChange = (e, name) => {
    const { value } = e.target;
    setSelectedOrder((prev) => {
      const updatedOrder = { ...prev };
      updatedOrder[name] = parseInt(value, 10);
      return updatedOrder;
    });
  };

  const getDisabledOptions = (name) => {
    const selectedValues = selectedOrder
      ? Object.values(selectedOrder).filter((value) => value !== 0)
      : [];

    return selectedValues;
  };

  const resetPosition = () => {
    setSelectedOrder({
      slider: 0,
      newSectionWithImages: 0,
      newSectionWithOutImages: 0,
      grid: 0,
      form: 0,
    });
  };

  useEffect(() => {
    fetchPages();
  }, []);

  console.log(formData.descriptionEn, "formData.descriptionEn");

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row card pt-3">
        <h3>Edit Page</h3>
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <div className="text-end  w-100">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={() => resetPosition()}
                  >
                    Reset Position
                  </button>
                </div>

                {activeLanguage === "en" ? (
                  <>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "slider")}
                      value={selectedOrder?.slider}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="slug" className="form-label ">
                        Slider
                      </label>
                      {sliderImages &&
                        sliderImages.map((_file, _index) => (
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label mt-3">
                              Image
                            </label>
                            <div
                              style={{
                                display: "flex",
                                gap: "1%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`${url}/${_file.image}`}
                                alt="no image"
                                style={{
                                  width: "15vw",
                                  height: "10vw",
                                  justifyContent: "center",
                                  border: "1px solid black",
                                }}
                              />
                              <input
                                type="file"
                                className="form-control"
                                id="slug"
                                name="slug"
                                // value={viewUploadedImage(_file.image)}
                                onChange={(ev) => fileUpload(ev, _index)}
                                required
                              />
                              <div
                                style={{
                                  padding: "1%",
                                  height: "2vw",
                                  width: "2vw",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                onClick={() => deleteFile(_index)}
                              >
                                &times;
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "10vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addNewImage}
                      >
                        <div style={{ fontWeight: "bold" }}>Add New Image</div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) =>
                        handleOrderChange(e, "newSectionWithImages")
                      }
                      value={selectedOrder?.newSectionWithImages}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="slug" className="form-label mt-3">
                        Add New Section With Image
                      </label>
                      {newSectionWithImages &&
                        newSectionWithImages.map((_section, _index) => (
                          <div className="mb-3">
                            <div className="w-100">
                              <JoditEditor
                                className="w-100"
                                ref={editorNewSectionWithImage}
                                value={_section.content || ""}
                                tabIndex={2}
                                onChange={(newContent) => {
                                  newSectionWithImages[_index].content =
                                    newContent;
                                }}
                              />
                            </div>

                            {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                            <div
                              style={{
                                display: "flex",
                                gap: "1%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`${url}/${_section.image}`}
                                alt="no image"
                                style={{
                                  width: "15vw",
                                  height: "10vw",
                                  justifyContent: "center",
                                  border: "1px solid black",
                                  marginTop: "2%",
                                }}
                              />
                              <div style={{ width: "100%" }}>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  // value={viewUploadedImage(_section.image)}
                                  onChange={(ev) =>
                                    uploadSectionImage(ev, _index)
                                  }
                                  required
                                  style={{
                                    marginBottom: "1%",
                                    marginTop: "3%",
                                  }}
                                />
                                {/* 
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={_section.content}
                                  onChange={(event, editor) => {
                                    newSectionWithImages[_index].content =
                                      editor.getData();
                                    // setFieldValue("description", editor.getData());
                                  }}
                                /> */}
                              </div>

                              <div
                                style={{
                                  padding: "1%",
                                  height: "2vw",
                                  width: "2vw",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                onClick={() =>
                                  deleteNewSectionWithImages(_index)
                                }
                              >
                                &times;
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "11vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addNewSectionWithImages}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          Add New Section
                        </div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) =>
                        handleOrderChange(e, "newSectionWithOutImages")
                      }
                      value={selectedOrder?.newSectionWithOutImages}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <div className="mt-3">
                        <label htmlFor="slug" className="form-label ">
                          Add New Section Without Image
                        </label>
                        {newSectionWithoutImages &&
                          newSectionWithoutImages.map((_section, _index) => (
                            <div className="mb-3">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                {/* <CKEditor
                                  editor={ClassicEditor}
                                  data={_section.content}
                                  onChange={(event, editor) => {
                                    newSectionWithoutImages[_index].content =
                                      editor.getData();
                                    // setFieldValue("description", editor.getData());
                                  }}
                                /> */}

                                <JoditEditor
                                  className="w-100 mb-3"
                                  ref={editornewSectionWithoutImages}
                                  value={_section.content || ""}
                                  tabIndex={2}
                                  onChange={(newContent) => {
                                    newSectionWithoutImages[_index].content =
                                      newContent;
                                  }}
                                />

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    deleteNewSectionWithoutImages(_index)
                                  }
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "11vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addNewSectionWithoutImages}
                      >
                        Add New Section
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "grid")}
                      value={selectedOrder?.grid}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("slider").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="slug" className="form-label mt-3">
                        Grid Layout
                      </label>
                      {grid &&
                        grid.map((_section, _index) => (
                          <div className="mb-3">
                            {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                            <div
                              style={{
                                display: "flex",
                                gap: "1%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`${url}/${_section.image}`}
                                alt="no image"
                                style={{
                                  width: "15vw",
                                  height: "10vw",
                                  justifyContent: "center",
                                  border: "1px solid black",
                                  marginTop: "2%",
                                }}
                              />
                              <input
                                placeholder="Enter label"
                                type="text"
                                className="form-control"
                                id="titleAr"
                                name="titleAr"
                                value={_section.content}
                                onChange={(ev) =>
                                  handleGridLabelChange(ev, _index)
                                }
                                required
                              />

                              <input
                                placeholder="Enter Description"
                                type="text"
                                className="form-control"
                                id="description_ar"
                                name="description_ar"
                                value={_section?.description}
                                onChange={(ev) =>
                                  handleGridDescriptionChange(ev, _index)
                                }
                                required
                              />

                              <input
                                placeholder="Select Image"
                                type="file"
                                className="form-control"
                                id="slug"
                                name="slug"
                                // value={viewUploadedImage(_section.image)}
                                onChange={(ev) => uploadGridImage(ev, _index)}
                                required
                              />

                              <div
                                style={{
                                  padding: "1%",
                                  height: "2vw",
                                  width: "2vw",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                onClick={() => deleteGridElemment(_index)}
                              >
                                &times;
                              </div>
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          padding: "1%",
                          height: "2vw",
                          width: "15vw",
                          backgroundColor: "green",
                          borderRadius: ".4vw",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          // fontSize: "1.5vw",
                        }}
                        onClick={addGridElement}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          Add New Grid Element
                        </div>
                      </div>
                    </div>
                    <select
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => handleOrderChange(e, "form")}
                    >
                      <option value="" selected disabled>
                        Select Position
                      </option>
                      {Array.from([0, 1, 2, 3, 4]).map((e, i) => (
                        <option
                          key={i}
                          value={e}
                          disabled={getDisabledOptions("form").includes(e)}
                        >
                          Position {e + 1}
                        </option>
                      ))}
                    </select>
                    <div className="card mb-3 p-3">
                      <label htmlFor="metaTitleEn" className="form-label">
                        Form URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="formUrl"
                        name="formUrl"
                        value={formData.formUrl}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display As Landing Page</label>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            name="insurance"
                            type="checkbox"
                            checked={isLandingPage}
                            onChange={() => {
                              setIsLandingPage(!isLandingPage);
                            }}
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display Form</label>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            name="insurance"
                            type="checkbox"
                            checked={isForm}
                            onChange={() => {
                              setIsForm(!isForm);
                            }}
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 p-3">
                      <label>Display Back Button</label>
                      <div className="col-12">
                        <div className="form-check">
                          <label className="form-check-label">
                            {" "}
                            <input
                              name="backBtn"
                              type="checkbox"
                              checked={isBackBtn}
                              onChange={() => {
                                setIsBackBtn(!isBackBtn);
                              }}
                              className="form-check-input"
                            />
                            Yes{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  // <>
                  //   <div dir="rtl">
                  //     <label htmlFor="slug" className="form-label ">
                  //       Slider
                  //     </label>
                  //     {sliderImages &&
                  //       sliderImages.map((_file, _index) => (
                  //         <div className="mb-3">
                  //           <label htmlFor="slug" className="form-label mt-3">
                  //             Image
                  //           </label>
                  //           <div
                  //             style={{
                  //               display: "flex",
                  //               gap: "1%",
                  //               justifyContent: "center",
                  //               alignItems: "center",
                  //             }}
                  //           >
                  //             <input
                  //               type="file"
                  //               className="form-control"
                  //               id="slug"
                  //               name="slug"
                  //               // value={viewUploadedImage(_file.image)}
                  //               onChange={(ev) => fileUpload(ev, _index)}
                  //               required
                  //             />
                  //             <div
                  //               style={{
                  //                 padding: "1%",
                  //                 height: "2vw",
                  //                 width: "2vw",
                  //                 backgroundColor: "red",
                  //                 borderRadius: "50%",
                  //                 color: "white",
                  //                 display: "flex",
                  //                 textAlign: "center",
                  //                 alignItems: "center",
                  //                 justifyContent: "center",
                  //                 cursor: "pointer",
                  //                 fontWeight: "bold",
                  //               }}
                  //               onClick={() => deleteFile(_index)}
                  //             >
                  //               &times;
                  //             </div>
                  //           </div>
                  //         </div>
                  //       ))}
                  //     <div
                  //       style={{
                  //         padding: "1%",
                  //         height: "2vw",
                  //         width: "10vw",
                  //         backgroundColor: "green",
                  //         borderRadius: ".4vw",
                  //         color: "white",
                  //         display: "flex",
                  //         textAlign: "center",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         cursor: "pointer",
                  //         // fontSize: "1.5vw",
                  //       }}
                  //       onClick={addNewImage}
                  //     >
                  //       <div style={{ fontWeight: "bold" }}>Add New Image</div>
                  //     </div>

                  //     <label htmlFor="slug" className="form-label mt-3">
                  //       Add New Section With Image
                  //     </label>
                  //     {newSectionWithImages &&
                  //       newSectionWithImages.map((_section, _index) => (
                  //         <div className="mb-3">
                  //           {/* <label htmlFor="slug" className="form-label">
                  //       Image
                  //     </label> */}
                  //           <div
                  //             style={{
                  //               display: "flex",
                  //               gap: "1%",
                  //               justifyContent: "center",
                  //               alignItems: "center",
                  //             }}
                  //           >
                  //             <CKEditor
                  //               editor={ClassicEditor}
                  //               data=""
                  //               onChange={(event, editor) => {
                  //                 newSectionWithImages[_index].content =
                  //                   editor.getData();
                  //                 // setFieldValue("description", editor.getData());
                  //               }}
                  //               config={{
                  //                 language: {
                  //                   ui: "ar", // UI language
                  //                   content: "ar", // Content language
                  //                 },
                  //                 contentsLangDirection: "rtl", // Content direction
                  //                 toolbar: [
                  //                   "heading",
                  //                   "|",
                  //                   "bold",
                  //                   "italic",
                  //                   "|",
                  //                   "link",
                  //                   "bulletedList",
                  //                   "numberedList",
                  //                   "|",
                  //                   "indent",
                  //                   "outdent",
                  //                   "|",
                  //                   "imageUpload",
                  //                   "blockQuote",
                  //                   "insertTable",
                  //                   "mediaEmbed",
                  //                   "|",
                  //                   "undo",
                  //                   "redo",
                  //                   "|",
                  //                   "selectAll",
                  //                 ],
                  //                 heading: {
                  //                   options: [
                  //                     {
                  //                       model: "paragraph",
                  //                       title: "Paragraph",
                  //                       class: "ck-heading_paragraph",
                  //                     },
                  //                     {
                  //                       model: "heading1",
                  //                       view: "h1",
                  //                       title: "Heading 1",
                  //                       class: "ck-heading_heading1",
                  //                     },
                  //                     {
                  //                       model: "heading2",
                  //                       view: "h2",
                  //                       title: "Heading 2",
                  //                       class: "ck-heading_heading2",
                  //                     },
                  //                     {
                  //                       model: "heading3",
                  //                       view: "h3",
                  //                       title: "Heading 3",
                  //                       class: "ck-heading_heading3",
                  //                     },
                  //                     {
                  //                       model: "heading4",
                  //                       view: "h4",
                  //                       title: "Heading 4",
                  //                       class: "ck-heading_heading4",
                  //                     },
                  //                     {
                  //                       model: "heading5",
                  //                       view: "h5",
                  //                       title: "Heading 5",
                  //                       class: "ck-heading_heading5",
                  //                     },
                  //                     {
                  //                       model: "heading6",
                  //                       view: "h6",
                  //                       title: "Heading 6",
                  //                       class: "ck-heading_heading6",
                  //                     },
                  //                   ],
                  //                 },
                  //               }}
                  //             />
                  //             <input
                  //               type="file"
                  //               className="form-control"
                  //               id="slug"
                  //               name="slug"
                  //               // value={viewUploadedImage(_section.image)}
                  //               onChange={(ev) =>
                  //                 uploadSectionImage(ev, _index)
                  //               }
                  //               required
                  //             />

                  //             <div
                  //               style={{
                  //                 padding: "1%",
                  //                 height: "2vw",
                  //                 width: "2vw",
                  //                 backgroundColor: "red",
                  //                 borderRadius: "50%",
                  //                 color: "white",
                  //                 display: "flex",
                  //                 textAlign: "center",
                  //                 alignItems: "center",
                  //                 justifyContent: "center",
                  //                 cursor: "pointer",
                  //                 fontWeight: "bold",
                  //               }}
                  //               onClick={() =>
                  //                 deleteNewSectionWithImages(_index)
                  //               }
                  //             >
                  //               &times;
                  //             </div>
                  //           </div>
                  //         </div>
                  //       ))}
                  //     <div
                  //       style={{
                  //         padding: "1%",
                  //         height: "2vw",
                  //         width: "11vw",
                  //         backgroundColor: "green",
                  //         borderRadius: ".4vw",
                  //         color: "white",
                  //         display: "flex",
                  //         textAlign: "center",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         cursor: "pointer",
                  //         // fontSize: "1.5vw",
                  //       }}
                  //       onClick={addNewSectionWithImages}
                  //     >
                  //       <div style={{ fontWeight: "bold" }}>
                  //         Add New Section
                  //       </div>
                  //     </div>
                  //     <div className="mt-3">
                  //       <label htmlFor="slug" className="form-label ">
                  //         Add New Section Without Image
                  //       </label>
                  //       {newSectionWithoutImages &&
                  //         newSectionWithoutImages.map((_section, _index) => (
                  //           <div className="mb-3">
                  //             <div
                  //               style={{
                  //                 display: "flex",
                  //                 gap: "1%",
                  //                 width: "100%",
                  //                 alignItems: "center",
                  //               }}
                  //             >
                  //               <CKEditor
                  //                 editor={ClassicEditor}
                  //                 data=""
                  //                 onChange={(event, editor) => {
                  //                   newSectionWithoutImages[_index].content =
                  //                     editor.getData();
                  //                   // setFieldValue("description", editor.getData());
                  //                 }}
                  //                 config={{
                  //                   language: {
                  //                     ui: "ar", // UI language
                  //                     content: "ar", // Content language
                  //                   },
                  //                   contentsLangDirection: "rtl", // Content direction
                  //                   toolbar: [
                  //                     "heading",
                  //                     "|",
                  //                     "bold",
                  //                     "italic",
                  //                     "|",
                  //                     "link",
                  //                     "bulletedList",
                  //                     "numberedList",
                  //                     "|",
                  //                     "indent",
                  //                     "outdent",
                  //                     "|",
                  //                     "imageUpload",
                  //                     "blockQuote",
                  //                     "insertTable",
                  //                     "mediaEmbed",
                  //                     "|",
                  //                     "undo",
                  //                     "redo",
                  //                     "|",
                  //                     "selectAll",
                  //                   ],
                  //                   heading: {
                  //                     options: [
                  //                       {
                  //                         model: "paragraph",
                  //                         title: "Paragraph",
                  //                         class: "ck-heading_paragraph",
                  //                       },
                  //                       {
                  //                         model: "heading1",
                  //                         view: "h1",
                  //                         title: "Heading 1",
                  //                         class: "ck-heading_heading1",
                  //                       },
                  //                       {
                  //                         model: "heading2",
                  //                         view: "h2",
                  //                         title: "Heading 2",
                  //                         class: "ck-heading_heading2",
                  //                       },
                  //                       {
                  //                         model: "heading3",
                  //                         view: "h3",
                  //                         title: "Heading 3",
                  //                         class: "ck-heading_heading3",
                  //                       },
                  //                       {
                  //                         model: "heading4",
                  //                         view: "h4",
                  //                         title: "Heading 4",
                  //                         class: "ck-heading_heading4",
                  //                       },
                  //                       {
                  //                         model: "heading5",
                  //                         view: "h5",
                  //                         title: "Heading 5",
                  //                         class: "ck-heading_heading5",
                  //                       },
                  //                       {
                  //                         model: "heading6",
                  //                         view: "h6",
                  //                         title: "Heading 6",
                  //                         class: "ck-heading_heading6",
                  //                       },
                  //                     ],
                  //                   },
                  //                 }}
                  //               />

                  //               <div
                  //                 style={{
                  //                   padding: "1%",
                  //                   height: "2vw",
                  //                   width: "2vw",
                  //                   backgroundColor: "red",
                  //                   borderRadius: "50%",
                  //                   color: "white",
                  //                   display: "flex",
                  //                   textAlign: "center",
                  //                   alignItems: "center",
                  //                   justifyContent: "center",
                  //                   cursor: "pointer",
                  //                   fontWeight: "bold",
                  //                 }}
                  //                 onClick={() =>
                  //                   deleteNewSectionWithoutImages(_index)
                  //                 }
                  //               >
                  //                 &times;
                  //               </div>
                  //             </div>
                  //           </div>
                  //         ))}
                  //     </div>
                  //     <div
                  //       style={{
                  //         padding: "1%",
                  //         height: "2vw",
                  //         width: "11vw",
                  //         backgroundColor: "green",
                  //         borderRadius: ".4vw",
                  //         color: "white",
                  //         display: "flex",
                  //         textAlign: "center",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         cursor: "pointer",
                  //         fontWeight: "bold",
                  //         // fontSize: "1.5vw",
                  //       }}
                  //       onClick={addNewSectionWithoutImages}
                  //     >
                  //       Add New Section
                  //     </div>

                  //     <label htmlFor="slug" className="form-label mt-3">
                  //       Grid Layout
                  //     </label>
                  //     {grid &&
                  //       grid.map((_section, _index) => (
                  //         <div className="mb-3">
                  //           {/* <label htmlFor="slug" className="form-label">
                  //       Image
                  //     </label> */}
                  //           <div
                  //             style={{
                  //               display: "flex",
                  //               gap: "1%",
                  //               justifyContent: "center",
                  //               alignItems: "center",
                  //             }}
                  //           >
                  //             <input
                  //               placeholder="Enter label"
                  //               type="text"
                  //               className="form-control"
                  //               id="titleAr"
                  //               name="titleAr"
                  //               value={_section.content}
                  //               onChange={(ev) =>
                  //                 handleGridLabelChange(ev, _index)
                  //               }
                  //               required
                  //             />
                  //             <input
                  //               placeholder="Select Image"
                  //               type="file"
                  //               className="form-control"
                  //               id="slug"
                  //               name="slug"
                  //               // value={viewUploadedImage(_section.image)}
                  //               onChange={(ev) => uploadGridImage(ev, _index)}
                  //               required
                  //             />

                  //             <div
                  //               style={{
                  //                 padding: "1%",
                  //                 height: "2vw",
                  //                 width: "2vw",
                  //                 backgroundColor: "red",
                  //                 borderRadius: "50%",
                  //                 color: "white",
                  //                 display: "flex",
                  //                 textAlign: "center",
                  //                 alignItems: "center",
                  //                 justifyContent: "center",
                  //                 cursor: "pointer",
                  //                 fontWeight: "bold",
                  //               }}
                  //               onClick={() => deleteGridElemment(_index)}
                  //             >
                  //               &times;
                  //             </div>
                  //           </div>
                  //         </div>
                  //       ))}
                  //     <div
                  //       style={{
                  //         padding: "1%",
                  //         height: "2vw",
                  //         width: "15vw",
                  //         backgroundColor: "green",
                  //         borderRadius: ".4vw",
                  //         color: "white",
                  //         display: "flex",
                  //         textAlign: "center",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         cursor: "pointer",
                  //         // fontSize: "1.5vw",
                  //       }}
                  //       onClick={addGridElement}
                  //     >
                  //       <div style={{ fontWeight: "bold" }}>
                  //         Add New Grid Element
                  //       </div>
                  //     </div>
                  //   </div>
                  // </>
                  <>
                    <div dir="rtl">
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label ">
                          Slider
                        </label>
                        {sliderImages &&
                          sliderImages.map((_file, _index) => (
                            <div className="mb-3">
                              <label htmlFor="slug" className="form-label mt-3">
                                Image
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`${url}/${_file.image_ar}`}
                                  alt="no image"
                                  style={{
                                    width: "15vw",
                                    height: "10vw",
                                    justifyContent: "center",
                                    border: "1px solid black",
                                  }}
                                />
                                <input
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  // value={viewUploadedImage(_file.image)}
                                  onChange={(ev) => fileUpload(ev, _index)}
                                  required
                                />
                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => deleteFile(_index)}
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "10vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewImage}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Image
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label mt-3">
                          Add New Section With Image
                        </label>
                        {newSectionWithImages &&
                          newSectionWithImages.map((_section, _index) => (
                            <div className="mb-3">
                              {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`${url}/${_section.image_ar}`}
                                  alt="no image"
                                  style={{
                                    width: "15vw",
                                    height: "10vw",
                                    justifyContent: "center",
                                    border: "1px solid black",
                                    marginTop: "2%",
                                  }}
                                />
                                <div style={{ width: "100%" }}>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="slug"
                                    name="slug"
                                    // value={viewUploadedImage(_section.image)}
                                    onChange={(ev) =>
                                      uploadSectionImage(ev, _index)
                                    }
                                    required
                                    style={{
                                      marginBottom: "1%",
                                      marginTop: "3%",
                                    }}
                                  />
                                  {/* <CKEditor
                                    config={{
                                      language: {
                                        ui: "ar", // UI language
                                        content: "ar", // Content language
                                      },
                                      contentsLangDirection: "rtl", // Content direction
                                      toolbar: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "|",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "|",
                                        "indent",
                                        "outdent",
                                        "|",
                                        "imageUpload",
                                        "blockQuote",
                                        "insertTable",
                                        "mediaEmbed",
                                        "|",
                                        "undo",
                                        "redo",
                                        "|",
                                        "selectAll",
                                      ],
                                      heading: {
                                        options: [
                                          {
                                            model: "paragraph",
                                            title: "Paragraph",
                                            class: "ck-heading_paragraph",
                                          },
                                          {
                                            model: "heading1",
                                            view: "h1",
                                            title: "Heading 1",
                                            class: "ck-heading_heading1",
                                          },
                                          {
                                            model: "heading2",
                                            view: "h2",
                                            title: "Heading 2",
                                            class: "ck-heading_heading2",
                                          },
                                          {
                                            model: "heading3",
                                            view: "h3",
                                            title: "Heading 3",
                                            class: "ck-heading_heading3",
                                          },
                                          {
                                            model: "heading4",
                                            view: "h4",
                                            title: "Heading 4",
                                            class: "ck-heading_heading4",
                                          },
                                          {
                                            model: "heading5",
                                            view: "h5",
                                            title: "Heading 5",
                                            class: "ck-heading_heading5",
                                          },
                                          {
                                            model: "heading6",
                                            view: "h6",
                                            title: "Heading 6",
                                            class: "ck-heading_heading6",
                                          },
                                        ],
                                      },
                                    }}
                                    editor={ClassicEditor}
                                    data={_section.content_ar}
                                    onChange={(event, editor) => {
                                      newSectionWithImages[_index].content_ar =
                                        editor.getData();
                                      // setFieldValue("description", editor.getData());
                                    }}
                                  /> */}

                                  <JoditEditor
                                    ref={editorNewSectionWithImage}
                                    value={_section.content_ar}
                                    tabIndex={2}
                                    onChange={(newContent) => {
                                      newSectionWithImages[_index].content_ar =
                                        editor.getData();
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    deleteNewSectionWithImages(_index)
                                  }
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "11vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewSectionWithImages}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Section
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <div className="mt-3">
                          <label htmlFor="slug" className="form-label ">
                            Add New Section Without Image
                          </label>
                          {newSectionWithoutImages &&
                            newSectionWithoutImages.map((_section, _index) => (
                              <div className="mb-3">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1%",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <CKEditor
                                    config={{
                                      language: {
                                        ui: "ar", // UI language
                                        content: "ar", // Content language
                                      },
                                      contentsLangDirection: "rtl", // Content direction
                                      toolbar: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "|",
                                        "link",
                                        "bulletedList",
                                        "numberedList",
                                        "|",
                                        "indent",
                                        "outdent",
                                        "|",
                                        "imageUpload",
                                        "blockQuote",
                                        "insertTable",
                                        "mediaEmbed",
                                        "|",
                                        "undo",
                                        "redo",
                                        "|",
                                        "selectAll",
                                      ],
                                      heading: {
                                        options: [
                                          {
                                            model: "paragraph",
                                            title: "Paragraph",
                                            class: "ck-heading_paragraph",
                                          },
                                          {
                                            model: "heading1",
                                            view: "h1",
                                            title: "Heading 1",
                                            class: "ck-heading_heading1",
                                          },
                                          {
                                            model: "heading2",
                                            view: "h2",
                                            title: "Heading 2",
                                            class: "ck-heading_heading2",
                                          },
                                          {
                                            model: "heading3",
                                            view: "h3",
                                            title: "Heading 3",
                                            class: "ck-heading_heading3",
                                          },
                                          {
                                            model: "heading4",
                                            view: "h4",
                                            title: "Heading 4",
                                            class: "ck-heading_heading4",
                                          },
                                          {
                                            model: "heading5",
                                            view: "h5",
                                            title: "Heading 5",
                                            class: "ck-heading_heading5",
                                          },
                                          {
                                            model: "heading6",
                                            view: "h6",
                                            title: "Heading 6",
                                            class: "ck-heading_heading6",
                                          },
                                        ],
                                      },
                                    }}
                                    editor={ClassicEditor}
                                    data={_section.content_ar}
                                    onChange={(event, editor) => {
                                      newSectionWithoutImages[
                                        _index
                                      ].content_ar = editor.getData();
                                      // setFieldValue("description", editor.getData());
                                    }}
                                  /> */}

                                  <JoditEditor
                                    ref={editornewSectionWithoutImages}
                                    value={_section.content_ar || ""}
                                    tabIndex={2}
                                    onChange={(newContent) => {
                                      newSectionWithoutImages[
                                        _index
                                      ].content_ar = newContent;
                                    }}
                                  />

                                  <div
                                    style={{
                                      padding: "1%",
                                      height: "2vw",
                                      width: "2vw",
                                      backgroundColor: "red",
                                      borderRadius: "50%",
                                      color: "white",
                                      display: "flex",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      deleteNewSectionWithoutImages(_index)
                                    }
                                  >
                                    &times;
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "11vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addNewSectionWithoutImages}
                        >
                          Add New Section
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label htmlFor="slug" className="form-label mt-3">
                          Grid Layout
                        </label>
                        {grid &&
                          grid.map((_section, _index) => (
                            <div className="mb-3">
                              {/* <label htmlFor="slug" className="form-label">
                        Image
                      </label> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={`${url}/${_section.image_ar}`}
                                  alt="no image"
                                  style={{
                                    width: "15vw",
                                    height: "10vw",
                                    justifyContent: "center",
                                    border: "1px solid black",
                                    marginTop: "2%",
                                  }}
                                />
                                <input
                                  placeholder="Enter label"
                                  type="text"
                                  className="form-control"
                                  id="titleAr"
                                  name="titleAr"
                                  dir="rtl"
                                  value={_section.content_ar}
                                  onChange={(ev) =>
                                    handleGridLabelChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  placeholder="Enter Description"
                                  type="text"
                                  className="form-control"
                                  id="description_ar"
                                  name="description_ar"
                                  value={_section?.description_ar}
                                  onChange={(ev) =>
                                    handleGridDescriptionChange(ev, _index)
                                  }
                                  required
                                />

                                <input
                                  dir="rtl"
                                  placeholder="Select Image"
                                  type="file"
                                  className="form-control"
                                  id="slug"
                                  name="slug"
                                  // value={viewUploadedImage(_section.image)}
                                  onChange={(ev) => uploadGridImage(ev, _index)}
                                  required
                                />

                                <div
                                  style={{
                                    padding: "1%",
                                    height: "2vw",
                                    width: "2vw",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    color: "white",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => deleteGridElemment(_index)}
                                >
                                  &times;
                                </div>
                              </div>
                            </div>
                          ))}
                        <div
                          style={{
                            padding: "1%",
                            height: "2vw",
                            width: "15vw",
                            backgroundColor: "green",
                            borderRadius: ".4vw",
                            color: "white",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            // fontSize: "1.5vw",
                          }}
                          onClick={addGridElement}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Add New Grid Element
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display As Landing Page</label>
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              name="insurance"
                              type="checkbox"
                              checked={isLandingPage}
                              onChange={() => {
                                setIsLandingPage(!isLandingPage);
                              }}
                              className="form-check-input"
                            />
                            <label className="form-check-label"> Yes </label>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display Form</label>
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              name="insurance"
                              type="checkbox"
                              checked={isForm}
                              onChange={() => {
                                setIsForm(!isForm);
                              }}
                              className="form-check-input"
                            />
                            <label className="form-check-label"> Yes </label>
                          </div>
                        </div>
                      </div>
                      <div className="card mb-3 p-3">
                        <label>Display Back Button</label>
                        <div className="col-12">
                          <div className="form-check">
                            <label className="form-check-label">
                              {" "}
                              <input
                                name="backBtn"
                                type="checkbox"
                                checked={isBackBtn}
                                onChange={() => {
                                  setIsBackBtn(!isBackBtn);
                                }}
                                className="form-check-input"
                              />
                              Yes{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 mt-3">
                    <label htmlFor="slug" className="form-label">
                      Parent Slug
                    </label>
                    <select
                      className="form-control"
                      value={formData?.parentSlug}
                      name="parentSlug"
                      onChange={handleChange}
                    >
                      <option disabled>Select Parent Slug</option>
                      {parentSlugList &&
                        Array.isArray(parentSlugList) &&
                        parentSlugList.length > 0 &&
                        parentSlugList?.map((s, i) => (
                          <option key={i} value={s}>
                            {s}
                          </option>
                        ))}
                      <option value="">None</option>{" "}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="slug" className="form-label">
                      Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      name="slug"
                      value={formData.slug}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {activeLanguage === "en" ? (
                    <>
                      <div className="mb-3">
                        <label htmlFor="titleEn" className="form-label">
                          Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="titleEn"
                          name="titleEn"
                          value={formData.titleEn}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="descriptionEn" className="form-label">
                          Description (English)
                        </label>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={formData.descriptionEn}
                          onChange={(event, editor) =>
                            handleEditorChange(editor, "en")
                          }
                          config={{
                            language: "en",
                            contentsLangDirection: "ltr",
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "|",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "indent",
                              "outdent",
                              "|",
                              "imageUpload",
                              "blockQuote",
                              "insertTable",
                              "mediaEmbed",
                              "|",
                              "undo",
                              "redo",
                              "|",
                              "selectAll",
                            ],
                            heading: {
                              options: [
                                {
                                  model: "paragraph",
                                  title: "Paragraph",
                                  class: "ck-heading_paragraph",
                                },
                                {
                                  model: "heading1",
                                  view: "h1",
                                  title: "Heading 1",
                                  class: "ck-heading_heading1",
                                },
                                {
                                  model: "heading2",
                                  view: "h2",
                                  title: "Heading 2",
                                  class: "ck-heading_heading2",
                                },
                                {
                                  model: "heading3",
                                  view: "h3",
                                  title: "Heading 3",
                                  class: "ck-heading_heading3",
                                },
                                {
                                  model: "heading4",
                                  view: "h4",
                                  title: "Heading 4",
                                  class: "ck-heading_heading4",
                                },
                                {
                                  model: "heading5",
                                  view: "h5",
                                  title: "Heading 5",
                                  class: "ck-heading_heading5",
                                },
                                {
                                  model: "heading6",
                                  view: "h6",
                                  title: "Heading 6",
                                  class: "ck-heading_heading6",
                                },
                              ],
                            },
                          }}
                        /> */}

                        <JoditEditor
                          ref={editor}
                          value={formData.descriptionEn || ""}
                          tabIndex={1}
                          onChange={(newContent) =>
                            handleEditorChange(newContent, "en")
                          }
                          // onBlur={(newContent) =>
                          //   handleEditorChange(newContent, "en")
                          // }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaTitleEn" className="form-label">
                          Form URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formUrl"
                          name="formUrl"
                          value={formData.formUrl}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaTitleEn" className="form-label">
                          Meta Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaTitleEn"
                          name="metaTitleEn"
                          value={formData.metaTitleEn}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="metaDescriptionEn"
                          className="form-label"
                        >
                          Meta Description (English)
                        </label>
                        <textarea
                          className="form-control"
                          id="metaDescriptionEn"
                          name="metaDescriptionEn"
                          rows="2"
                          value={formData.metaDescriptionEn}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Meta Keywords (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaKeywordsEn"
                          name="metaKeywordsEn"
                          value={formData.metaKeywordsEn}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Back Button Text
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="backBtnText"
                          name="backBtnText"
                          value={formData.backBtnText}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Redirect URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="redirectUrl"
                          name="redirectUrl"
                          value={formData.redirectUrl}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="titleAr" className="form-label">
                          Title (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="titleAr"
                          name="titleAr"
                          dir="rtl"
                          value={formData.titleAr}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="descriptionAr" className="form-label">
                          Description (Arabic)
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={formData.descriptionAr || ""}
                          tabIndex={2}
                          onChange={(newContent) =>
                            handleEditorChange(newContent, "ar")
                          }
                          // onBlur={(newContent) =>
                          //   handleEditorChange(newContent, "ar")
                          // }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaTitleEn" className="form-label">
                          Form URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formUrl"
                          name="formUrl"
                          value={formData.formUrl}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="metaTitleAr" className="form-label">
                          Meta Title (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaTitleAr"
                          name="metaTitleAr"
                          dir="rtl"
                          value={formData.metaTitleAr}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label
                          htmlFor="metaDescriptionAr"
                          className="form-label"
                        >
                          Meta Description (Arabic)
                        </label>
                        <textarea
                          className="form-control"
                          id="metaDescriptionAr"
                          name="metaDescriptionAr"
                          rows="2"
                          value={formData.metaDescriptionAr}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="metaKeywordsAr" className="form-label">
                          Meta Keywords (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaKeywordsAr"
                          name="metaKeywordsAr"
                          dir="rtl"
                          value={formData.metaKeywordsAr}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Back Button Text (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="backBtnText_ar"
                          name="backBtnText_ar"
                          value={formData.backBtnText_ar}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Redirect URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="redirectUrl"
                          name="redirectUrl"
                          value={formData.redirectUrl}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPageForm;
