import PropTypes from "prop-types";
import "./Preview.css";
import { MdClose } from "react-icons/md";

const Preview = ({ formItems, onClose, formData }) => {
  console.log(formItems);
  return (
    <div className="preview-container">
      <div className="preview-header">
        <h2>{formData.formName || "Form Preview"}</h2>
        <button onClick={onClose} className="close-button">
          <MdClose />
        </button>
      </div>
      <div className="preview-content">
        {formItems.length === 0 ? (
          <p>No components added to preview.</p>
        ) : (
          formItems.map((item) => (
            <div key={item.id} className="preview-item">
              {item.type === "heading" && (
                <h1
                  style={{
                    fontSize: `${formData.headingFontSize}px`,
                  }}
                >
                  {formData.heading?.heading || "Default Heading"}
                </h1>
              )}
              {item.type === "description" && (
                <p>{formData.description?.heading || "Default description"}</p>
              )}
              {item.type === "text" && (
                <input
                  type="text"
                  name="text"
                  placeholder={formData.text?.heading || "Enter Text"}
                />
              )}
              {item.type === "textarea" && (
                <textarea
                  name="textarea"
                  placeholder={formData.textarea?.heading || "Enter Details"}
                  rows={3}
                ></textarea>
              )}
              {item.type === "number" && (
                <input
                  type="number"
                  name="number"
                  value={formData.number?.value || ""}
                  placeholder={formData.number?.heading || "Enter Number"}
                />
              )}
              {item.type === "file" && (
                <input
                  type="file"
                  name="file"
                  placeholder={formData.file?.heading || "Upload File"}
                />
              )}
              {item.type === "dropdown" && (
                <select name={`dropdown-${item.id}`} id={`dropdown-${item.id}`}>
                  <option>{formData.dropdown?.heading || "Dropdown"}</option>
                  {(formData.dropdown?.dropdownOptions || []).map((option) => (
                    <option key={option.id} value={option.text}>
                      {option.text}
                    </option>
                  ))}
                </select>
              )}
              {item.type === "radio" && (
                <div className="radio-container">
                  <p>{item.heading}</p>
                  {item?.radioOptions?.map((option) => (
                    <div className="option-text-container" key={option.id}>
                      <input
                        type="radio"
                        id={`${item.id}-${option.id}`}
                        name={item.id}
                        value={option.text}
                        checked={
                          formData.radio?.[item.id]?.value === option.text
                        }
                        onChange={() => {
                          console.log(`Selected option: ${option.text}`);
                        }}
                      />
                      <p>{option.text || "No text provided"}</p>
                    </div>
                  ))}
                </div>
              )}
              {item.type === "date" && (
                <input
                  type="date"
                  name="date"
                  value={formData.date?.value || ""}
                  placeholder={formData.date?.heading || "Enter Date"}
                />
              )}
              {item.type === "time" && (
                <input
                  type="time"
                  name="time"
                  value={formData.time?.value || ""}
                  placeholder={formData.time?.heading || "Enter Time"}
                />
              )}
              {item.type === "button" && (
                <button className="primary-button">
                  {formData.buttonText || "Submit"}
                </button>
              )}
              {item.type === "mobile" && (
                <div className="mobile-number container">
                  <select
                    name={`mobile-${item.id}`}
                    id={`mobile-${item.id}`}
                    value={formData.mobile?.countryCode || ""}
                    disabled
                  >
                    <option value="">Select Country Code</option>
                  </select>
                  <input
                    type="text"
                    name="mobile-number"
                    value={formData.mobile?.value || ""}
                    disabled
                    placeholder={
                      formData.mobile?.heading || "Enter Mobile Number"
                    }
                  />
                </div>
              )}
              {item.type === "email" && (
                <input
                  type="email"
                  name="email"
                  value={formData.email?.value || ""}
                  placeholder={formData.email?.heading || "Enter Email"}
                />
              )}
              {item.type === "country" && (
                <select
                  name={`country-${item.id}`}
                  id={`country-${item.id}`}
                  value={formData.country?.selectedCountry || ""}
                >
                  <option value="">
                    {formData.country?.heading || "Select Country"}
                  </option>
                </select>
              )}
              {item.type === "image" && (
                <div className="preview-image-group">
                  <input type="file" name="image" required accept="image/*" />
                  <div className="show-image">
                    {formData.image?.value && (
                      <img src={formData.image?.value} alt="SelectedImage" />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

Preview.propTypes = {
  formItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    formName: PropTypes.string,
    headingFontSize: PropTypes.number,
    heading: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
    }),
    description: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
    }),
    text: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    textarea: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    number: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      required: PropTypes.bool,
    }),
    file: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    dropdown: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
      dropdownOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string,
        })
      ),
    }),
    checkBox: PropTypes.shape({
      heading: PropTypes.string,
      selected: PropTypes.bool,
      value: PropTypes.string,
    }),
    radio: PropTypes.shape({
      heading: PropTypes.string,
      selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
    date: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    time: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    buttonText: PropTypes.string,
    mobile: PropTypes.shape({
      heading: PropTypes.string,
      countryCode: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    email: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
    country: PropTypes.shape({
      heading: PropTypes.string,
      selectedCountry: PropTypes.string,
      required: PropTypes.bool,
    }),
    image: PropTypes.shape({
      heading: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
    }),
  }).isRequired,
};

export default Preview;
