import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux'
import { url } from '../Common/Url';
import { Link } from 'react-router-dom';

export default function DashboardComponent() {

    const { user, token } = useSelector((state) => state.user)
    const [doctor, setDoctor] = useState(0)
    const [speciality, setSpeciality] = useState(0)
    const [Clinic, setClinic] = useState(0)
    const [excellance, setExcellance] = useState(0)

    const get_trust_data = async () => {
        const { data } = await axios.get(`${url}/api/v1/user/dashboard`, {
            headers: {
                token: `${token}`
            },
        });
        console.log(data)
        setDoctor(data.doctors)
        setSpeciality(data.specialties)
        setClinic(data.clinics)
        setExcellance(data.excellance)

    }
    useEffect(() => {
        get_trust_data()
    }, [])

    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">

                <div class="col-xl-4 mb-4 col-lg-5 col-12">
                    <div class="card">
                        <div class="d-flex align-items-end row">
                            <div class="col-7">
                                <div class="card-body text-nowrap">
                                    <h5 class="card-title mb-0">{user?.name}! 🎉</h5>
                                    <p class="mb-2">Welcome to admin dashboard</p>
                                    <Link to="/admin/profile" class="btn btn-primary waves-effect waves-light">Update Profile</Link>

                                </div>
                            </div>
                            <div class="col-5 text-center text-sm-left">
                                <div class="card-body pb-0 px-0 px-md-4">
                                    <img src="../../assets/img/illustrations/card-advance-sale.png" height="140" alt="view sales" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-8 mb-4 col-lg-7 col-12">
                    <div class="card h-100">
                        <div class="card-header">
                            <div class="d-flex justify-content-between mb-3">
                                <h5 class="card-title mb-0">Statistics</h5>

                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row gy-3">
                                <div class="col-md-3 col-6">
                                    <Link to="/admin/specialities-list">
                                        <div class="d-flex align-items-center">
                                            <div class="badge rounded-pill bg-label-primary me-3 p-2">
                                                <i class="ti ti-chart-pie-2 ti-sm"></i>
                                            </div>
                                            <div class="card-info">
                                                <h5 class="mb-0">{speciality}</h5>
                                                <small>Specialities</small>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div class="col-md-3 col-6">
                                    <Link to="/admin/doctors-list">
                                        <div class="d-flex align-items-center">
                                            <div class="badge rounded-pill bg-label-info me-3 p-2">
                                                <i class="ti ti-users ti-sm"></i>
                                            </div>
                                            <div class="card-info">
                                                <h5 class="mb-0">{doctor}</h5>
                                                <small>Doctor</small>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div class="col-md-3 col-6">
                                    <Link to="/admin/clinics-list">
                                        <div class="d-flex align-items-center">
                                            <div class="badge rounded-pill bg-label-danger me-3 p-2">
                                                <i class="ti ti-users ti-sm"></i>
                                            </div>
                                            <div class="card-info">
                                                <h5 class="mb-0">{Clinic}</h5>
                                                <small>Clinics</small>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div class="col-md-3 col-6">
                                    <Link to="/admin/excellance">
                                        <div class="d-flex align-items-center">
                                            <div class="badge rounded-pill bg-label-success me-3 p-2">
                                                <i class="ti ti-currency-dollar ti-sm"></i>
                                            </div>
                                            <div class="card-info">
                                                <h5 class="mb-0">{excellance}</h5>
                                                <small>Excellance</small>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}