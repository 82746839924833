import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ToggleButton.css";

const ToggleButton = ({ onToggle, initialState = false }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);
    onToggle(newState);
  };

  return (
    <div className="toggle-button" onClick={handleToggle}>
      <span className="toggle-label">
        {isToggled ? "Required" : "Not Required"}
      </span>
      <div className={`toggle-circle ${isToggled ? "toggled" : ""}`}></div>
    </div>
  );
};

ToggleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  initialState: PropTypes.bool,
};

export default ToggleButton;
