import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url, Image } from "./Url";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function SideBarComponents() {
  const { token } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({});
  const [dropdown, setDropdown] = useState(false);
  const [tele, setTele] = useState(false);
  const [isClinicOpen, setIsClinicOpen] = useState(false);
  const [isDoctorOpen, setIsDoctorOpen] = useState(false);
  const [patientcare, setPateintCare] = useState(false);
  const [seniorcare, setSeniorCare] = useState(false);
  const [skincare, setSkibcare] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ivvitamins, setIvvitamins] = useState(false);
  const [category, setCategory] = useState(false);
  const [prevent, setPrevent] = useState(false);
  const [specialclinic, setSpecialclinic] = useState(false);
  const [rtpcr, setRtpcr] = useState(false);
  const [wellness, setWellness] = useState(false);
  const [events, setEvents] = useState(false);
  const [offers, setOffers] = useState(false);
  const [news, setNews] = useState(false);
  const [pages, setPages] = useState(false);
  const [forms, setForms] = useState(false);
  const navigate = useNavigate();

  const [emotional, setEmotional] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handlertpcr = (e) => {
    e.preventDefault();
    setRtpcr(!rtpcr);
  };

  const handleDoctorToggle = (e) => {
    e.preventDefault();
    setIsDoctorOpen(!isDoctorOpen);
  };

  const handleClinicToggle = (e) => {
    e.preventDefault();
    setIsClinicOpen(!isClinicOpen);
  };

  const getSettingData = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/setting/get`, {
        headers: { token },
      });
      setFormData(data.data);
    } catch (error) {
      toast.error("Failed to fetch settings data.");
    }
  };

  const showDropdown = (e) => {
    e.preventDefault();
    setDropdown(!dropdown);
  };

  const teleconsultant = (e) => {
    e.preventDefault();
    setTele(!tele);
  };
  const handleCategory = (e) => {
    e.preventDefault();
    setCategory(!category);
  };
  const handleEvents = (e) => {
    e.preventDefault();
    setEvents(!events);
  };
  const handleOffers = (e) => {
    e.preventDefault();
    setOffers(!offers);
  };

  const handleNews = (e) => {
    e.preventDefault();
    setNews(!news);
  };
  const handlePatientcare = (e) => {
    e.preventDefault();
    setPateintCare(!patientcare);
  };

  const handleSeniorcare = (e) => {
    e.preventDefault();
    setSeniorCare(!seniorcare);
  };

  const handleaesthetics = (e) => {
    e.preventDefault();
    setSkibcare(!skincare);
  };

  const handlevitamins = (e) => {
    e.preventDefault();
    setIvvitamins(!ivvitamins);
  };
  const handleEmotional = (e) => {
    e.preventDefault();
    setEmotional(!emotional);
  };
  const handleSpecialCLinic = (e) => {
    e.preventDefault();
    setSpecialclinic(!specialclinic);
  };
  const handlePreventPackage = (e) => {
    e.preventDefault();
    setPrevent(!prevent);
  };

  const handleWellness = (e) => {
    e.preventDefault();
    setWellness(!wellness);
  };

  const handlePages = (e) => {
    e.preventDefault();
    setPages(!pages);
  };

  const handleForms = (e) => {
    e.preventDefault();
    setForms(!forms);
  };

  useEffect(() => {
    getSettingData();
  }, []);

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo mt-4">
        <Link to="/admin/dashboard" className="app-brand-link">
          <span className="app-brand-logo demo">
            <img
              src={`${Image}/${formData.image}`}
              width={"165px"}
              height={"90px"}
            />
          </span>
        </Link>

        <Link
          to="#"
          className="layout-menu-toggle menu-link text-large ms-auto"
        >
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </Link>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1">
        <li className="menu-item active open">
          <ul className="menu-sub">
            <li className="menu-item active">
              <Link to="/admin/dashboard" className="menu-link">
                <div data-i18n="Analytics">Dashboard</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            Pages
          </span>
        </li>

        <li className={`menu-item  ${pages ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handlePages}>
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Pages</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/create-page", { state: { type: "event" } });
                }}
                className="menu-link"
              >
                <div data-i18n="Add New">Add New</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/page-list", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="All Pages">All Pages</div>
              </div>
            </li>
          </ul>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            Forms
          </span>
        </li>

        <li className={`menu-item  ${forms ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handleForms}>
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Forms</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/create-form", { state: { type: "event" } });
                }}
                className="menu-link"
              >
                <div data-i18n="Add New">Add New</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/form-list", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="All Pages">All Forms</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/lead-forms", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="All Pages">Lead Forms</div>
              </div>
            </li>
          </ul>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Pages">
            {" "}
            CMS
          </span>
        </li>
        {/* <li className="menu-item"> */}
        {/* <Link to="/admin/home" className="menu-link">
            <i className="menu-icon tf-icons ti ti-smart-home"></i>
            <div data-i18n="Email">Home</div>
          </Link> */}
        {/* </li> */}
        <li className="menu-item">
          <Link to="/admin/about" className="menu-link">
            <i className="menu-icon tf-icons ti ti-file"></i>
            <div data-i18n="Chat">About us</div>
          </Link>
        </li>

        <li className={`menu-item  ${isDoctorOpen ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleDoctorToggle}
          >
            <i className="menu-icon tf-icons ti ti-users"></i>
            <div data-i18n="Doctors">Doctors</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/add-doctor" className="menu-link">
                <div data-i18n="Add Doctor">Add Doctor</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/doctors-list" className="menu-link">
                <div data-i18n="Doctors List">Doctors List</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className={`menu-item  ${isOpen ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handleToggle}>
            <i className="menu-icon tf-icons ti ti-users"></i>
            <div data-i18n="Specialities">Specialities</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/specialities" className="menu-link">
                <div data-i18n="Add Speciality">Add Speciality</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/specialities-list" className="menu-link">
                <div data-i18n="Specialities List">Specialities List</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className={`menu-item  ${isClinicOpen ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleClinicToggle}
          >
            <i className="menu-icon tf-icons ti ti-users"></i>
            <div data-i18n="Clinics">Clinics</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/add-clinic" className="menu-link">
                <div data-i18n="Add Clinic">Add Clinic</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/clinics-list" className="menu-link">
                <div data-i18n="Clinics List">Clinics List</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/manage-facilities" className="menu-link">
                <div data-i18n="Facilities">Facilities</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className={`menu-item  ${tele ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={teleconsultant}
          >
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Teleconsultation</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/teleconsultation" className="menu-link">
                <div data-i18n="Add Slider">Slider</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/teleconsultation/trust" className="menu-link">
                <div data-i18n="Description List">Trust</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/teleconsultation/step" className="menu-link">
                <div data-i18n="Specialities List">Step</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className={`menu-item  ${dropdown ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={showDropdown}>
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Aster At Home</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/asterathome/slider" className="menu-link">
                <div data-i18n="Add Speciality">Slider</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/description" className="menu-link">
                <div data-i18n="Specialities List">Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/about" className="menu-link">
                <div data-i18n="Specialities List">About</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/service" className="menu-link">
                <div data-i18n="Specialities List">Service</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/admin/asterathome/office/treatment"
                className="menu-link"
              >
                <div data-i18n="Specialities List">Treatment</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/office" className="menu-link">
                <div data-i18n="Specialities List">Office Work</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/athotel" className="menu-link">
                <div data-i18n="Specialities List">Hotel Work</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/notes" className="menu-link">
                <div data-i18n="Specialities List">Notes</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/asterathome/faq" className="menu-link">
                <div data-i18n="Specialities List">FAQ</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu-item">
          <Link to="/admin/excellance" className="menu-link">
            <i className="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Chat">Excellance</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/admin/download" className="menu-link">
            <i className="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Chat">Downloads</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/admin/special/service" className="menu-link">
            <i className="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Chat">Special Service</div>
          </Link>
        </li>
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            PATIENT CARE
          </span>
        </li>
        <li className={`menu-item  ${patientcare ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handlePatientcare}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Patient Care</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/healthtool" className="menu-link">
                <div data-i18n="Add Speciality">Health Tool</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/bmi" className="menu-link">
                <div data-i18n="Specialities List">BMI</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/gerdrisk" className="menu-link">
                <div data-i18n="Specialities List">Gerd Risk</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/aster/hospital" className="menu-link">
                <div data-i18n="Specialities List">Aster Hospital</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/insurance/affiliates" className="menu-link">
                <div data-i18n="Specialities List">Insurance Affiliates</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/admin/off-shore-medical-assessments"
                className="menu-link"
              >
                <div data-i18n="Specialities List">Offshore medical</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${seniorcare ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleSeniorcare}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Senior Care</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/seniorcare" className="menu-link">
                <div data-i18n="Add Speciality">Image & Notes</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/seniorcare/philosopy" className="menu-link">
                <div data-i18n="Specialities List">Philosophy</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/seniorcare/benefit" className="menu-link">
                <div data-i18n="Specialities List">Benefit</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/seniorcare/offer" className="menu-link">
                <div data-i18n="Specialities List">Offer</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${skincare ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleaesthetics}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Aesthetics by Aster</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/skincare" className="menu-link">
                <div data-i18n="Add Speciality">Slider & Notes</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/dermotology" className="menu-link">
                <div data-i18n="Specialities List">Dermotology</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/counter" className="menu-link">
                <div data-i18n="Specialities List">Counter</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/doctorspeak" className="menu-link">
                <div data-i18n="Specialities List">Doctor Speak</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${ivvitamins ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handlevitamins}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">IV Vitamins</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/vitamin" className="menu-link">
                <div data-i18n="Add Speciality">Slider & Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/vitamin/therapy" className="menu-link">
                <div data-i18n="Specialities List">Vitamin Therapy</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/vitamin/work" className="menu-link">
                <div data-i18n="Specialities List">Vitamin Works</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/vitamin/category" className="menu-link">
                <div data-i18n="Specialities List">
                  Vitamin Therapy Category
                </div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/vitamin/therapy/type" className="menu-link">
                <div data-i18n="Specialities List">Therapy Type</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/vitamin/faq" className="menu-link">
                <div data-i18n="Specialities List">Faq</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${emotional ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleEmotional}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Emotional Mental Health</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/emotional/slider" className="menu-link">
                <div data-i18n="Add Speciality">Slider</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/emotional/description" className="menu-link">
                <div data-i18n="Specialities List">Description</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${specialclinic ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleSpecialCLinic}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Special Clinics</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/special/clinic" className="menu-link">
                <div data-i18n="Add Speciality">Slider & Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/special/clinic/type" className="menu-link">
                <div data-i18n="Specialities List">Type</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className={`menu-item  ${prevent ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handlePreventPackage}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">Prevent Health Packages</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link
                to="/admin/prevent/health/description"
                className="menu-link"
              >
                <div data-i18n="Add Speciality">Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/prevent/health/package" className="menu-link">
                <div data-i18n="Specialities List">Health Packages</div>
              </Link>
            </li>
          </ul>
        </li>
        <li className={`menu-item  ${rtpcr ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handlertpcr}>
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">RTPCR Test</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/rtpcr/description" className="menu-link">
                <div data-i18n="Add RTPCR">Slider & Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/rtpcr/step" className="menu-link">
                <div data-i18n="RTPCR List">Step</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/rtpcr/trust" className="menu-link">
                <div data-i18n="RTPCR List">Trust</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/rtpcr/faq" className="menu-link">
                <div data-i18n="RTPCR List">Faq</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            CORPORATE WELLNESS
          </span>
        </li>
        <li className={`menu-item  ${wellness ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleWellness}
          >
            <i className="menu-icon tf-icons ti ti-forms"></i>
            <div data-i18n="Specialities">WELLNESS</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <Link to="/admin/wellness/slider" className="menu-link">
                <div data-i18n="wellness slider">Slider & Description</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/wellness/package" className="menu-link">
                <div data-i18n="wellness package">Package</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/wellness/client" className="menu-link">
                <div data-i18n="wellness client">Client</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/wellness/program" className="menu-link">
                <div data-i18n="wellness program">Program</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/wellness/testimonial" className="menu-link">
                <div data-i18n="wellness testimonial">Testimonials</div>
              </Link>
            </li>
          </ul>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            Blogs
          </span>
        </li>

        <li className={`menu-item  ${category ? "open" : ""}`}>
          <a
            href="#"
            className="menu-link menu-toggle"
            onClick={handleCategory}
          >
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Blogs</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog", {
                    state: { type: "blog" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">All Blogs</div>
              </div>
            </li>{" "}
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog/comment-list");
                }}
                className="menu-link"
              >
                <div data-i18n="Comments List">Comments List</div>
              </div>
            </li>{" "}
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog/add", {
                    state: { type: "blog" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Add New</div>
              </div>
            </li>{" "}
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog/category/add", {
                    state: { type: "blog" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Category</div>
              </div>
            </li>{" "}
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog/tag/add", {
                    state: { type: "blog" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Tag</div>
              </div>
            </li>
          </ul>
        </li>
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            Events
          </span>
        </li>

        <li className={`menu-item  ${events ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handleEvents}>
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Events</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog", { state: { type: "event" } });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">All Events</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/blog/add", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Add New</div>
              </div>
            </li>
          </ul>
        </li>
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            Offers
          </span>
        </li>
        <li className={`menu-item  ${offers ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handleOffers}>
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">Offers</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/offers", { state: { type: "event" } });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">All Offers</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/offers/add", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Add Offers</div>
              </div>
            </li>
          </ul>
        </li>
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            News
          </span>
        </li>

        <li className={`menu-item  ${news ? "open" : ""}`}>
          <a href="#" className="menu-link menu-toggle" onClick={handleNews}>
            <i className="menu-icon tf-icons ti ti-square"></i>
            <div data-i18n="Specialities">News</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/news", { state: { type: "event" } });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">All News</div>
              </div>
            </li>
            <li className="menu-item">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/admin/news/add", {
                    state: { type: "event" },
                  });
                }}
                className="menu-link"
              >
                <div data-i18n="Add Slider">Add New</div>
              </div>
            </li>
          </ul>
        </li>
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="Menu">
            {" "}
            Menu
          </span>
        </li>

        <li className="menu-item">
          <Link to="/admin/header" className="menu-link">
            <i className="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Header Menu">Header Menu</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/admin/footer" className="menu-link">
            <i className="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Footer Menu">Footer Menu</div>
          </Link>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text" data-i18n="SETTINGS">
            {" "}
            SETTINGS
          </span>
        </li>
        <li className="menu-item">
          <Link to="/admin/forms" className="menu-link">
            <i className="menu-icon tf-icons ti ti-mail"></i>
            <div data-i18n="Lead Forms">Lead Forms</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/admin/contact" className="menu-link">
            <i className="menu-icon tf-icons ti ti-lifebuoy"></i>
            <div data-i18n="Chat">Contact Us</div>
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/admin/careers" className="menu-link">
            <i className="menu-icon tf-icons ti ti-lifebuoy"></i>
            <div data-i18n="Chat">Careers</div>
          </Link>
        </li>

        <li className="menu-item">
          <Link to="/admin/settings" className="menu-link">
            <i className="menu-icon tf-icons ti ti-settings"></i>
            <div data-i18n="Chat">Settings</div>
          </Link>
        </li>
      </ul>
    </aside>
  );
}
