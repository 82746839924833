import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../Components/Common/Url";
import DataTable from "react-data-table-component";
import { formatDate } from "../utils/common";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const generateColumns = (formData) => {
  if (!formData || formData.length === 0) return [];

  const keys = Object.keys(formData[0]);
  const updatedkeys = keys.filter((k) => k !== "__v");

  return updatedkeys.map((key) => {
    const rowKey =
      key === "createdAt" || key === "updatedAt"
        ? (row) => formatDate(row[key]) || "-"
        : (row) => row[key] || "-";
    return {
      name: key.charAt(0).toUpperCase() + key.slice(1),
      selector: rowKey,
      sortable: true,
    };
  });
};

const LeadForms = () => {
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState(null);
  const [columns, setColumns] = useState([]);

  const fetchForms = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/form-builder/list`);
      if (data) {
        setForms(data);
      }
    } catch (error) {
      console.error(
        "Unable to fetch forms. Please try again later.",
        error.message
      );
    }
  };

  const getFormDataByName = async (formName) => {
    try {
      const { data } = await axios.get(
        `${url}/api/v1/form-builder/data/${formName}`
      );
      if (data) {
        setFormData(data?.formData || []);
        setColumns(generateColumns(data?.formData || []));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const exportToExcel = () => {
    if (!formData || formData.length === 0) return;

    const data = formData.map((form, index) => {
      const formattedForm = {};
      columns.forEach((col) => {
        formattedForm[col.name] = col.selector(form) || "-";
      });
      return {
        "#": index + 1,
        ...formattedForm,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lead Forms");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "LeadForms.xlsx");
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">
              Lead Forms{" "}
              <button
                disabled={!formData}
                onClick={exportToExcel}
                className="btn btn-primary float-end"
              >
                Export to Excel
              </button>
            </h5>

            <div className="card-body">
              <select
                className="form-control mb-3"
                onChange={(e) => getFormDataByName(e.target.value)}
              >
                <option disabled selected>
                  Select Form
                </option>
                {forms &&
                  Array.isArray(forms) &&
                  forms.length > 0 &&
                  forms.map((f, i) => (
                    <option key={i} value={f?.formName}>
                      {f?.formName}
                    </option>
                  ))}
              </select>

              <DataTable
                title=""
                columns={columns}
                data={formData || []}
                pagination
                highlightOnHover
                responsive
                defaultSortFieldId={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadForms;
