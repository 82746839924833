import axios from "axios";
import { url } from "../Components/Common/Url";

export function removeSpaceFromString(s) {
  return s.replace(/\s+/g, "");
}

export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const uploadFileAndGetUrl = async (file, token) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      `${url}/api/v1/setting/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${token}`,
        },
      }
    );

    const imagePath = response.data.imagePath;
    return imagePath;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Error uploading file"); // Throw error to handle it elsewhere
  }
};
